import { UserInterestCard } from "./UserInterestCard";
import { Button, Collapse, Grid } from "@material-ui/core";
import { useState } from "react";
import { ExpanderButton } from "./ExpanderButton";
import { ContentContainer } from "./ContentContainer";
import { makeStyles } from "@material-ui/styles";
import LinkToProfileInterests from "./LinkToProfileInterests";
import { useHistory } from "react-router-dom";

export const SidebarUserInterests = ({ interests, categories, expandCategoryHandler }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const navigateToInterests = (categoryId) => {
    const path = "/profile/interests";
    expandCategoryHandler(categoryId);
    history.push(path);
  }

  return (
    <>
      <ContentContainer
        classes={{ root: classes.root }}
        title={"Interests"}
        control={<LinkToProfileInterests />}
      >
        {interests.length === 0 && (<Grid container spacing={1}>
          {categories.slice(0, 4).map((category, index) => (
            <Grid key={index} item xs={6} title={"Get to know your colleagues"}>
              <UserInterestCard
                key={category.id.toString()}
                userInterest={category}
                onClick={() => navigateToInterests(category.id)}
              />
            </Grid>
          ))}
          <Grid item xs={12} title={"Get to know your colleagues"}>
            <Collapse in={expanded}>
              <Grid container spacing={1}>
                {categories
                  .slice(4, categories.length)
                  .map((category, index) => (
                    <Grid key={index} item xs={6}>
                      <UserInterestCard
                        key={category.id.toString()}
                        userInterest={category}
                        onClick={() => navigateToInterests(category.id)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Collapse>
          </Grid>
        </Grid>)}
        {interests.length === 0 && categories.length > 4 && (
          <ExpanderButton
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          />
        )}
        {interests.length > 0 && (<Grid container spacing={1}>
          {interests.slice(0, 4).map((userInterest, index) => (
            <Grid key={index} item xs={6}>
              <UserInterestCard
                key={userInterest.id.toString()}
                userInterest={userInterest}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Collapse in={expanded}>
              <Grid container spacing={1}>
                {interests
                  .slice(4, interests.length)
                  .map((userInterest, index) => (
                    <Grid key={index} item xs={6}>
                      <UserInterestCard
                        key={userInterest.id.toString()}
                        userInterest={userInterest}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Collapse>
          </Grid>
        </Grid>)}
        {interests.length > 4 && (
          <ExpanderButton
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          />
        )}
        <Button
          type="button"         
          fullWidth
          variant="contained"
          className={classes.submit}
          onClick={() => navigateToInterests(0)}
        >          
          Get to know your colleagues
        </Button>
      </ContentContainer>
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "20px",
  },
  submit: {
    width: "100%",
    marginTop: "1.5vw",
    height: "2.5em",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
  },
}));
