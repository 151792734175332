import React, { useState } from 'react';
import profilePicture from './../assets/avatar.png';
import add from './../assets/center_column/change_status_add_photo_icon.svg';
import paperclip from './../assets/center_column/paper_clip.svg';
import emo from './../assets/center_column/emoji.svg';
import { makeStyles, withStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import { ContentContainer } from './ContentContainer';
import { useAuthState } from 'react-firebase-hooks/auth';
import ButtonMUI from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import './Chat.css';
import Send from '@material-ui/icons/Send';

export const StatusTextbox = withStyles({
  root: {
    '& label.MuiFormLabel-root': {
      fontSize: '13px',
      color: '#979797',
    },
    '& .MuiFilledInput-root': {
      fontSize: '13px',
      color: '#979797',
      borderRadius: '20px',
      backgroundColor: 'white'
    }
  }
})(TextField);

const Button = ({ icon, sendMessageHandler }) => {
  const classes = useStyles();

  return (
    <div className={classes.button} onClick={sendMessageHandler}>
      <img src={icon} alt='' />
    </div>
  );
};

export const ComposePost = (props) => {
  const classes = useStyles();
  const { auth, firestore, db } = props.authProvider.fireBaseProvider;
  const [userFireBase] = useAuthState(auth);
  const user = props.user;
  const postsRef = firestore.collection('wall/'+user.company+'/posts');
  //const query = postsRef.orderBy('dateCreated').limit(25);
  //const [posts] = useCollectionData(query, { idField: 'id' });
  const [formValue, setFormValue] = useState('');

  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL } = auth;

    await postsRef.add({      
      recordType: 'basicPost',
      userName: user.fullName,
      avatarSmall: user.avatarImg,
      altUserAvatar: 'user avatar',
      userProfileLink: '#',
      jobTitle: user.position,
      dateCreated: db.firestore.FieldValue.serverTimestamp(),
      addedPhotos: '',
      contentText: formValue,
      userEmail: auth.currentUser.email,
      contentPhoto: []
    })

    setFormValue('');
  }


  return (
    <ContentContainer classes={{ root: classes.root }}>
      <img className={classes.profilePicture} src={profilePicture} alt='' />
      <textarea rows="2" className={classes.sendMessageForm + " scrollable"} style={{ width: "100%", resize: "none" }} value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="How are you?" id="messageInput" />
      <Button icon={add} />
      <Button icon={paperclip} />
      <ButtonMUI onClick={sendMessage} disabled={!formValue} className={classes.sendButton}><SendIcon /></ButtonMUI>
    </ContentContainer>
  );
};

export const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '20px',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  textField: {
    flexGrow: '1',
    width: '100%',
    margin: '0 16px'
  },
  profilePicture: {
    width: '24px',
    height: '24px'
  },
  sendButton: {
    color: "darkorange",
    margin: '0px',
    minWidth: '25px',
    minHeight: '16px'
  },
  button: {
    cursor: 'pointer',
    margin: '0 8px',
    minWidth: '25px',
    minHeight: '16px'
  },
  sendMessageForm: {
    lineHeight: '1',
    width: '100%',
    fontSize: '1rem',
    borderRadius: '3em',
    /*border: 'solid 1px darkorange',*/
    outline: 'none',
    border: 'none',
    padding: '0 10px',
    margin: '5px 5px 5px 5px;'
  }
}));
