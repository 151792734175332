import { Link } from "react-router-dom";
import editIcon from "./../assets/left_column/edit_icon.svg";

const LinkToProfileEdit = () => (
  <Link to="/profile/edit">
    <img src={editIcon} alt="" />
  </Link>
);

export default LinkToProfileEdit;
