import { makeStyles } from "@material-ui/core";
import React from "react";
import { PostContainer } from "./PostContainer";
import { ShowAlbumPhotos } from "./PhotoGallery";

export const Post = ({wallRecord}) => {
    const classes = useStyles();

    const ViewAll = () => {
        return (
            <div className={classes.viewAll}>
                <a className={classes.viewAllLink}
                   href={wallRecord.linkViewAllPhoto}> View all photo </a>
            </div>
        )
    }

    const showViewAll = Boolean(wallRecord.contentPhoto.length);

    const FourPhotos = ({photos}) => {
        return(
            <div className={classes.fourPhotos}>
                {photos.map((photo, index) => {
                    return (
                        <div key={index}>
                            <img src={photo} />
                        </div>
                    )
                })}
            </div>
        )
    }

    const photoStyleHandler = (photos) => {
        if (wallRecord.contentPhoto.length <= 4) {
            return <FourPhotos photos={photos} />
        } else if (wallRecord.contentPhoto.length >= 5) {
            return <ShowAlbumPhotos srcs={photos}/>
        }
    }

    return (
        <PostContainer timemark={wallRecord.timeWhenAdded} avatarSrc={wallRecord.avatarSmall} fullname={wallRecord.userName} position={wallRecord.jobTitle} action={wallRecord.addedPhotos} >
            <pre className={classes.textContent}>{wallRecord.contentText}</pre>
            {photoStyleHandler(wallRecord.contentPhoto)}
            {showViewAll && <ViewAll />}
        </PostContainer>
    );
};

const useStyles = makeStyles(() => ({
    textContent:{
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        color: '#5C5C5C',
        marginBottom: '8px',
        whiteSpace:'pre-wrap',
        wordWrap: 'break-word',
    },
    viewAll:{
        marginBottom: '8px',
        textAlign:'center',
    },
    viewAllLink:{
        textDecoration: 'none',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '1.25px',
        color: '#FF8106',
        alignItems: 'center',
    },
    smallerPhotosInFive:{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
        gridGap: '8px',
        '& > div:nth-child(4)': {
            filter: 'opacity(50%)',
        }
    },
    counter: {
        borderRadius: '50%',
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        width: '30%',
        height: '30%',
        display: 'block',
        padding: '0.5vw',
        left: '35%',
        top: '35%',
        fontSize: '1vw',
    },
    fourPhotos:{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        alignItems: 'center',
        gridGap: '8px'
    }
}));
