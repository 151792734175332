import React, { useRef, useState, useEffect } from 'react';
import './Chat.css';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import { SvgIcon } from "../components/SvgIcon";

import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SendIcon from '@material-ui/icons/Send';
import Draggable from "react-draggable";
import { Resizable } from 'react-resizable';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from "react-router-dom";
import './resizable.css';
//import { useFireBaseUser } from '../services/authProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  avatarButton:
  {
    justifyContent: 'left',
  },
  conversationSection:
  {
    borderRight: 'solid 1px darkorange',
    overflowY: 'scroll',
    height: '100%',
    backgroundColor: 'lightYellow',
  },
  activeConversation: {
    fontWeight: "bold",
    justifyContent: 'left',
  },
  insertEmotion:
  {
    height: '1.5em',
    color: 'white',
    fontSize: '2rem',
  },
  emotionGridItem:
  {
    fontSize: 'xx-large',
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
  signOut: {
    cursor: 'pointer',
  },
  chat: {
    position: "relative",
    cursor: "pointer",
    marginLeft: "5px",
    color: "white",
  },
  container: {
    display: "flex",
    position: "absolute",
    width: "24px",
    height: "24px",
    top: 0,
    left: 0,
    fontSize: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  inviteSection: {
    borderBottom: "solid 1px darkorange",
    display: "flex",
    justifyContent: "space-between",
  },
  searchSection: {
    borderRight: "solid 1px darkorange",
    paddingTop: "5px",
    backgroundColor: 'lightYellow',
  },
  searchInput: {
    width: "98%",
    height: "80%",
    border: "solid 1px darkorange",
  },
}));

/*
firebase.initializeApp({
  apiKey: "AIzaSyALxrnt59NgM4emgG_qjweJhunOOt-__wc",
  authDomain: "wellbe-dd809.firebaseapp.com",
  projectId: "wellbe-dd809",
  storageBucket: "wellbe-dd809.appspot.com",
  messagingSenderId: "571382342641",
  appId: "1:571382342641:web:d01ed874881c765850da50",
  measurementId: "G-27X8E9YPSL"
})*/

//var auth = firebase.auth();
//const firestore = firebase.firestore();
//const analytics = firebase.analytics();

function ChatWindow(props) {
  //const { fireBaseUser } = useFireBaseUser();  
  const { auth, firestore, db } = props.authProvider.fireBaseProvider;
  const appUser = props.user;
  const [user] = useAuthState(auth);
  const [width, setWidth] = useState(700);
  const [height, setHeight] = useState(465);
  const { isChatOpen, openChatHandler, selectedConversation, selectConversationHandler } = props;
  const classes = useStyles();

  // On top layout
  const onResize = (event, { element, size, handle }) => {
    setWidth(size.width);
    setHeight(size.height);
  };

  const [isClosed, setIsClosed] = useState('')

  const closeChat = () => {
    //setIsClosed('none');
    //auth.signOut();
    openChatHandler();
  }
  var ignoreBlur = true;

  const setIgnoreBlur = () => {
    ignoreBlur = true;
  }

  const clearIgnoreBlur = () => {
    ignoreBlur = false;
  }

  const handleBlur = () => {
    if (ignoreBlur) return;
    setIsClosed('none');
  }  

  return (
    <>
      <Draggable handle="#draggable-dialog-title">
        <Resizable height={height} width={width} onResize={onResize} minConstraints={[594, 465]} maxConstraints={[1000, 1000]}>
          <div className="chatBox" style={{ width: width + 'px', height: height + 'px', display: (isChatOpen) ? 'flex' : 'none' }} onMouseDown={setIgnoreBlur} onMouseUp={clearIgnoreBlur} onMouseOut={clearIgnoreBlur} onBlur={handleBlur}>
            <div className="chatApp" style={{ width: width + 'px', height: height + 'px' }}>
              <header id="draggable-dialog-title">
                <h1></h1>
                <SignOut closeChatHandler={() => closeChat()} user={auth} />
              </header>

              <section style={{ width: width + 'px', height: height - 25 + 'px' }}>
                
                {user ? <Chat
                  width={width}
                  height={height}
                  user={user}
                  firestore={firestore}
                  db={db}
                  appUser={appUser}
                  selectedConversation={selectedConversation}
                  selectConversationHandler={selectConversationHandler}                  
                /> : <SignIn user={user} />}
              </section>

            </div>
          </div>
        </Resizable>
      </Draggable>

    </>
  );
}

function Conversation(props) {
  const { name, id, imgURL, participants, isOneToOne } = props.conversation;
  const userProfiles = props.userProfiles;
  const isActive = props.isActive;
  const firestore = props.firestore;
  const appUser = props.appUser;
  const classes = useStyles();

  const unreadMessagesRef = firestore.collection(appUser.company + '/chat/' + appUser.externalId + 'unreadMessages/')
    .where('conversationId', '==', id);
  const [unreadMessages] = useCollectionData(unreadMessagesRef, { idField: 'id' });

  const filtered = (userProfiles.filter(function (value, index, arr) {
    return participants.includes(value.externalId) && value.externalId !== (appUser.externalId !== undefined) ? appUser.externalId : '';
  }));

  const conversationImgUrl = (imgURL !== "" && imgURL !== null) ? imgURL : ((filtered[0] !== undefined) ? filtered[0].avatarImg : '');

  return (<>
    <div>
      <Button className={(id === isActive) ? classes.activeConversation : classes.avatarButton} onClick={() => props.click(id)} fullWidth>
        <Avatar alt="user" src={conversationImgUrl} />&nbsp;{name.replace(appUser.fullName, '')}
        {(unreadMessages !== undefined) && (unreadMessages.length > 0) &&
          <div className={classes.chat}>
            <div>
              <SvgIcon icon={"chat"} size={"24px"} color={"#FF8106"} />
              <div className={classes.container}>{(unreadMessages !== undefined) ? unreadMessages.length : 0}</div>
            </div>
          </div>}
      </Button>
    </div>
  </>)
}

function Chat(props) {
  //  var conversationId = "YBsLATLyxnZt6Gf2rSfm"
  const auth = props.user;
  const firestore = props.firestore;
  const appUser = props.appUser;
  const db = props.db;
  const conversationsRef = firestore.collection(appUser.company + '/chat/conversations');  
  const query = conversationsRef.where('participants', 'array-contains-any', [auth.uid]);
  const [conversations] = useCollectionData(query, { idField: 'id' });
  const [conversationsFiltered, setConversationsFiltered] = useState(conversations);
  const [conversationId, setConversationId] = useState("1");
  const width = props.width;
  const height = props.height;
  const selectedConversation = props.selectedConversation;
  const selectConversationHandler = props.selectConversationHandler;
  const [conversationFilter, setConversationFilter] = useState("");

  //const userProfiles = props.userProfiles;

  if (conversations !== undefined) {
    conversations.sort(function (a, b) {
      return b.updatedAt - a.updatedAt;
    });
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenAddToChatPopup = (event) => {
    setAnchorEl(event.currentTarget);    
  };

  const handleCloseAddToChatPopup = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'invite-popover' : undefined;

  const [userProfiles, setUserProfiles] = useState([]);
  const [filteredUserProfiles, setFilteredUserProfiles] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URI}/api/current/companyProfiles`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          let filtered = result.filter(function (value, index, arr) {
            return value.externalId !== appUser.externalId;//auth.uid;
          });          
          /*
          if (conversations !== undefined && appUser.externalId !== undefined) {
            let oneToOneConverstions = conversations.filter(function (value, index, arr) {
              return value.isOneToOne;
            });

            let existingConversations = oneToOneConverstions.map(function (obj) {
              return obj.participants.filter(function (value, index, arr) {
                return value !== appUser.externalId;
              })[0];
            });


            let userProfilesToConversations = filtered.filter(function (value, index, arr) {
              let include = (!existingConversations.includes(value.externalId))
              return include;
            })
              .map(function (obj) {
                return {
                  "imgURL": obj.avatarImg,
                  "name": obj.fullName,
                  "isOneToOne": true,
                  "updatedAt": {
                    "seconds": 0,
                    "nanoseconds": 0
                  },
                  "participants": [
                    obj.externalId
                  ],
                  "id": obj.externalId,
                  "isNew": true,
                  "userProfile": obj
                };
              });
            setFilteredUserProfiles(userProfilesToConversations);
          }
          */
          setUserProfiles(filtered);
        },
        (error) => { }
      );
  }, [appUser, conversations, setFilteredUserProfiles]);

  useEffect(() => {
    setConversationsFiltered(conversations);
  }, [conversations]);

  
  useEffect(() => {
    if (conversations !== undefined && appUser.externalId !== undefined) {
      let oneToOneConverstions = conversations.filter(function (value, index, arr) {
        return value.isOneToOne;
      });

      let existingConversations = oneToOneConverstions.map(function (obj) {
        return obj.participants.filter(function (value, index, arr) {
          return value !== appUser.externalId;
        })[0];
      });


      let userProfilesToConversations = userProfiles.filter(function (value, index, arr) {
        let include = (!existingConversations.includes(value.externalId))
        return include;
      })
        .map(function (obj) {
          return {
            "imgURL": obj.avatarImg,
            "name": obj.fullName,
            "isOneToOne": true,
            "updatedAt": {
              "seconds": 0,
              "nanoseconds": 0
            },
            "participants": [
              obj.externalId
            ],
            "id": obj.externalId,
            "isNew": true,
            "userProfile": obj
          };
        });

      if (conversationFilter !== "") {
        let filtered = conversations.concat(userProfilesToConversations).filter(function (value, index, arr) {
          return value.name.replace(appUser.fullName, "").toLowerCase().includes(conversationFilter.toLowerCase());
        });
        setConversationsFiltered(filtered);
      }
      else {
        setConversationsFiltered(conversations);
      }
    }
  }, [conversations, setFilteredUserProfiles, appUser, conversationFilter]);
  

  const handleProfileSearch = (event) => {
    setConversationFilter(event.target.value);
    /*if (conversations !== undefined && appUser.externalId !== undefined) {
      let oneToOneConverstions = conversations.filter(function (value, index, arr) {
        return value.isOneToOne;
      });

      let existingConversations = oneToOneConverstions.map(function (obj) {
        return obj.participants.filter(function (value, index, arr) {
          return value !== appUser.externalId;
        })[0];
      });


      let userProfilesToConversations = userProfiles.filter(function (value, index, arr) {
        let include = (!existingConversations.includes(value.externalId))
        return include;
      })
        .map(function (obj) {
          return {
            "imgURL": obj.avatarImg,
            "name": obj.fullName,
            "isOneToOne": true,
            "updatedAt": {
              "seconds": 0,
              "nanoseconds": 0
            },
            "participants": [
              obj.externalId
            ],
            "id": obj.externalId,
            "isNew": true,
            "userProfile": obj
          };
        });

      if (event.target.value !== "") {
        let filtered = conversations.concat(userProfilesToConversations).filter(function (value, index, arr) {
          return value.name.replace(appUser.fullName, "").toLowerCase().includes(event.target.value.toLowerCase());
        });
        setConversationsFiltered(filtered);
      }
      else {
        setConversationsFiltered(conversations);
      }
    }*/
  }

  
  const switchConversation = (id) => {
    setConversationId(id);    
  }

  const classes = useStyles();
  

  const startNewConversation = async () => {
    const conversationsRef = firestore.collection(appUser.company + '/chat/conversations');

    for (var i = 0; i < conversations.length; i++) {
      if (JSON.stringify(conversations[i].participants) === JSON.stringify([auth.uid, selectedConversation.externalId]) ||
        JSON.stringify(conversations[i].participants) === JSON.stringify([selectedConversation.externalId, auth.uid])) {
        setConversationId(conversations[i].id);
        selectConversationHandler({ externalId: "", fullName: "", imgURL: "" });
        return;
      }
    }

    if (selectedConversation.externalId !== '' && selectedConversation.externalId !== undefined && selectedConversation.fullName !== '') {
      var res = await conversationsRef.add({
        name: selectedConversation.fullName + ' ' + appUser.fullName,
        isOneToOne: true,
        participants: [auth.uid, selectedConversation.externalId],
        updatedAt: db.firestore.FieldValue.serverTimestamp(),
        imgURL: ''
      })
      setConversationsFiltered(conversations);
      setConversationId(res.id);
    }    
  }

  const handleChange = (event) => {    
    selectConversationHandler({ externalId: event.target.value, fullName: event.currentTarget.childNodes[1].data, imgURL: event.currentTarget.childNodes[0].currentSrc });
  };

  const handleStartNewConversation = (profile) => {    
    selectConversationHandler({ externalId: profile.externalId, fullName: profile.fullName, imgURL: profile.avatarImg });    
    startNewConversation();
  }

  const addNewUserToConversation = (profile) => {
    const conversationsRef = firestore.collection(appUser.company + '/chat/conversations/');
    conversationsRef
      .doc(conversationId)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          let conversation = doc.data();
          let name = conversation.name;
          let participants = conversation.participants;
          let newConversation = -1;

          if (participants.indexOf(profile.externalId) !== -1) {
            return;
          }

          participants.push(profile.externalId);
          participants.sort(function (a, b) {
            if (a < b) { return -1; }
            if (a > b) { return 1; }
            return 0;
          });

          let participantsJson = JSON.stringify(participants);



          for (let i = 0; i < conversations.length; i++) {
            if (JSON.stringify(conversations[i].participants.sort(function (a, b) {
              if (a < b) { return -1; }
              if (a > b) { return 1; }
              return 0;
            })) === participantsJson) {
              newConversation = conversations[i].id;
            }
          }

          if (newConversation === -1) {
            var res = await conversationsRef.add({
              name: name + ' ' + profile.fullName,
              isOneToOne: false,
              participants: participants,
              updatedAt: db.firestore.FieldValue.serverTimestamp(),
              imgURL:'https://firebasestorage.googleapis.com/v0/b/wellbe-dd809.appspot.com/o/openMedia%2Fgroup_chat_default.png?alt=media&token=80c84b3a-c6e7-4e92-a84b-b7758b8d9e3a'
            })
            newConversation = res.id;
          };

          setConversationId(newConversation);
        }
      });
    setAnchorEl(null);
  }

  useEffect(() => {
    if (selectedConversation.externalId !== '') {      
      startNewConversation();      
    }
  }, [selectedConversation, startNewConversation]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={4} xl={4} md={4} className={classes.searchSection}>
          <input type="text" className={classes.searchInput} onChange={handleProfileSearch} value={conversationFilter} placeholder="search..."/>
        </Grid>
        <Grid item xs={8} xl={8} md={8} className={classes.inviteSection}>
          <span></span>
          <Button onClick={(e) => handleOpenAddToChatPopup(e)} title="Invite to active group chat"><GroupAddIcon/></Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloseAddToChatPopup}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >

            {userProfiles && userProfiles.map(
              profile => <div key={"div_"+profile.externalId}><Button key={profile.externalId} fullWidth className={classes.avatarButton} onClick={() => addNewUserToConversation(profile)}>
                <img className='chatImg' src={profile.avatarImg || 'https://wellbe.cloud/content/images/smiles/animated/smile.gif'} alt="User profile avatar"/>
                {profile.fullName}
              </Button>
              </div>)}

          </Popover>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={4} style={{ height: height - 62 + 'px' }}>
          <div className={'scrollable ' + classes.conversationSection} style={{ height: height - 62 - 39 + 'px' }}>
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="text"
            fullWidth
          >
              {conversationsFiltered && conversationsFiltered.map(convr =>
                <Conversation
                  key={convr.id}
                  appUser={appUser}
                  conversation={convr}
                  isActive={conversationId}
                  click={convr.isNew ? () => (handleStartNewConversation(convr.userProfile)) : (() => switchConversation(convr.id))}
                  firestore={firestore}
                  userProfiles={userProfiles} />)}             
            </ButtonGroup>
            </div>          
        </Grid>
        <Grid item xs={8}>
          <ChatRoom conversationId={conversationId} width={width} height={height} user={props.user} firestore={firestore} db={db} appUser={appUser} />
        </Grid>
      </Grid>
    </>
  );
}


function SignIn(props) {
  /*const { fireBaseUser } = useFireBaseUser();
  const [auth] = fireBaseUser.baseUser.context.auth();*/
  const history = useHistory();
  const signInWithGoogle = () => {
    history.push("/")
    //const provider = new firebase.auth.GoogleAuthProvider();
    //auth.signInWithPopup(provider);
    //const { auth } = props.user.fireBaseProvider;
    //auth.signInWithEmailAndPassword('fedyaev@hotmail.com', '123456789*');
  }

  return (
    <>
      <button className="sign-in" onClick={signInWithGoogle}>Please re-login</button>
    </>
  )

}

function SignOut(props) {
  /*const { fireBaseUser } = useFireBaseUser();
  const [auth] = fireBaseUser.baseUser.user;*/
  const classes = useStyles();
  const auth = props.user;
  const closeChatHandler = props.closeChatHandler;
  return auth && (
    <button className={classes.signOut} onClick={() => { closeChatHandler() }} >X</button>
  )
}


function ChatRoom(props) {
  const auth = props.user;
  const appUser = props.appUser;
  const firestore = props.firestore;
  const db = props.db;
  const dummy = useRef();
  const width = props.width;
  const height = props.height;
  const conversationId = props.conversationId;
  const messagesRef = firestore.collection(appUser.company + '/chat/conversations/' + conversationId + '/messages');
  const query = messagesRef.orderBy('createdAt').limit(25);
  const [messages] = useCollectionData(query, { idField: 'id' });
  const [formValue, setFormValue] = useState('');
  const unreadMessagesRef = firestore.collection(appUser.company + '/chat/' + auth.uid + 'unreadMessages/');
  const conversationRef = firestore.collection(appUser.company + '/chat/conversations/').doc(conversationId);

  var participants = [];

  firestore.collection(appUser.company + '/chat/conversations/')
    .doc(conversationId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        participants = doc.data().participants;
      }

    });

  //const [conversations] = useCollectionData(conversationsR, { idField: 'id' });
  /*const { fireBaseUser } = useFireBaseUser();
  const [auth] = fireBaseUser.baseUser.user;*/
  //const [lastMessageId, setLastMessageId] = useState((messages !== undefined && messages.length > 0)?messages[messages.length - 1].id:'');

  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL } = auth;
    if (conversationId !== "1") {

      await messagesRef.add({
        text: formValue,
        createdAt: db.firestore.FieldValue.serverTimestamp(),
        uid,
        photoURL: appUser.avatarImg,
        unread: true
      }).then((docRef) => {

        conversationRef.update({
          updatedAt: db.firestore.FieldValue.serverTimestamp()
        });

        var filtered = participants.filter(function (value, index, arr) {
          return value !== auth.uid;
        });

        for (var i = 0; i < filtered.length; i++) {
          var unreadMessages = firestore.collection(appUser.company + '/chat/' + filtered[i] + 'unreadMessages/');

          unreadMessages.add({
            messageId: docRef.id,
            conversationId: conversationId
          });
        }
      });


    }

    setFormValue('');
    dummy.current.parentNode.scrollTop = dummy.current.offsetTop;

  }

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addEmotion = (emotion) => {
    setFormValue(formValue + emotion);
  };

  const scrolled = (e) => {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {

      unreadMessagesRef.where('conversationId', '==', conversationId)
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            doc.ref.delete().then(() => {
              console.log("Document successfully deleted!");
            })
          });
        });
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (<>


    <main style={{ height: height - 62 - 38 - 48 + 'px' }} className="messagesMain" onScroll={(e) => scrolled(e)}>

      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} user={auth} isLast={true} messagesRef={messagesRef} appUser={appUser} unreadMessagesRef={unreadMessagesRef} />)}

      <span ref={dummy}></span>

    </main>
    <div className="sendMessageForm">
      <InsertEmoticonIcon className={classes.insertEmotion} onClick={handleClick} aria-describedby={id} />
      <textarea rows="1" className="scrollableW scrollable" style={{ width: "100%", resize: "none" }} value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Write something nice" id="messageInput" />

      <button onClick={sendMessage} disabled={!formValue} ><SendIcon /></button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container spacing={0}>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('🙂')}><span role="img" aria-label="SLIGHTLY SMILING FACE">🙂</span></IconButton>
          </Grid>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('😉')}><span role="img" aria-label="WINKING FACE">😉</span></IconButton>
          </Grid>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('😇')}><span role="img" aria-label="smiling face with halo">😇</span></IconButton>
          </Grid>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('👍')}><span role="img" aria-label="thumbs up">👍</span></IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('😄')}><span role="img" aria-label="Grinning Face with Smiling Eyes">😄</span></IconButton>
          </Grid>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('😂')}><span role="img" aria-label="Face with Tears of Joy">😂</span></IconButton>
          </Grid>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('🤓')}><span role="img" aria-label="Nerd Face">🤓</span></IconButton>
          </Grid>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('😔')}><span role="img" aria-label="Pensive Face">😔</span></IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('🙁')}><span role="img" aria-label="Slightly Frowning Face">🙁</span></IconButton>
          </Grid>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('😖')}><span role="img" aria-label="Confounded Face">😖</span></IconButton>
          </Grid>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('😢')}><span role="img" aria-label="Crying Face">😢</span></IconButton>
          </Grid>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('😡')}><span role="img" aria-label="Pouting Face">😡</span></IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('🤔')}><span role="img" aria-label="Thinking Face">🤔</span></IconButton>
          </Grid>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('👋')}><span role="img" aria-label="Waving Hand">👋</span></IconButton>
          </Grid>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('🥳')}><span role="img" aria-label="Partying Face">🥳</span></IconButton>
          </Grid>
          <Grid item className={classes.emotionGridItem}>
            <IconButton onClick={() => addEmotion('🧡')}><span role="img" aria-label="Heart">🧡</span></IconButton>
          </Grid>
        </Grid>
      </Popover>

    </div>
  </>)
}


function ChatMessage(props) {
  /*const { fireBaseUser } = useFireBaseUser();*/
  //const auth = fireBaseUser.baseUser.user;
  const auth = props.user;
  const appUser = props.appUser;
  const messagesRef = props.messagesRef;
  const { text, uid, photoURL, id, unread } = props.message;
  const isLast = props.isLast;
  const msg = useRef();

  const messageClass = uid === auth.uid ? 'sent' : 'received';
  const defaultImage = uid === auth.uid ? 'https://wellbe.cloud/content/images/smiles/animated/happy.gif' : 'https://wellbe.cloud/content/images/smiles/animated/smile.gif';

  const scrollToMessage = () => {
    if (msg.current !== undefined && msg.current !== null) {
      msg.current.parentNode.parentNode.scrollTop = msg.current.parentNode.offsetTop;
      if (uid !== auth.uid && unread) {
        var messageRef = messagesRef.doc(id);

        messageRef.update({
          unread: false
        })
      }
    }
  }

  const onMessageLoad = (isLast) => {
    setTimeout(scrollToMessage, 1000);
  }
  //onLoad2={onMessageLoad(isLast)}
  return (<>
    <div className={`message ${messageClass}`}>
      <img className='chatImg' src={photoURL || defaultImage} alt="user avatar of the chat message"/>
      <pre ref={msg}>{text}</pre>
    </div>
  </>)
}


export default ChatWindow;
