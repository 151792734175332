import { useStyles } from "./styles";
import DailySurveyAnswer from "./DailySurveyAnswer";

const DailySurveyQuestion = ({ question, onClick }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.question}>{question.text}</div>
      {/* <div className={classes.explanation}>
        Your answer will take less than 10 seconds. This will help us understand
        you better.
      </div> */}
      <div className={classes.moods}>
        {question.availableAnswers.map((answer, index) => (
          <DailySurveyAnswer
            key={answer.id}
            answer={answer}
            onClick={onClick}
          />
        ))}
      </div>
    </>
  );
};

export default DailySurveyQuestion;
