import { makeStyles } from "@material-ui/styles";
import albumLogo from './../assets/left_column/teambuilding.svg';

export const AlbumTitle = ({ alb }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img src={albumLogo} alt='' />
            <div style={{textAlign: 'start'} }>
                <div className={classes.title}>{alb.name}</div>
                <div className={classes.subtitle}>{alb.photoAmount} photos</div>
            </div>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        width: '120px',
        height: '120px',
        borderRadius: '20px',
        backgroundColor: 'white',
        fontWeight: '400',
        fontSize: '12px',
        textAlign: 'start',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    title: {
        color: '#5C5C5C'
    },
    subtitle: {
        color: '#FF8106'
    }
}));
