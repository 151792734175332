import React from "react";
import questionIcon from './../assets/center_column/question_icon.svg';
import { makeStyles } from "@material-ui/core";
import { ContentContainer } from "./ContentContainer";
import { PostTimemark } from './PostTimemark';

const SurveyTheme = ({ theme }) => {
    const classes = useStyles();

    return (
        <div className={classes.themeContainer}>
            <div className={classes.theme}>Theme</div>
            <div style={{ color: '#FF8106' }}>{theme}</div>
        </div>
    );
};

const SurveyOption = ({ text }) => {
    const classes = useStyles();

    return <div className={classes.optionContainer}>{text}</div>;
};

const PostComments = ({ count }) => {
    const classes = useStyles();

    return <div className={classes.comments}>{count} comments</div>;
};

export const SurveyPost = ({ wallRecord }) => {
    const classes = useStyles();

    const timemark = <PostTimemark value={wallRecord.timeWhenAdded} />;
    const help = <div style={{ cursor: 'pointer' }}><img src={questionIcon} alt=''/></div>;

    return (
        <ContentContainer classes={{ root: classes.root }} title={'Surveys'} control={timemark} controlTopLeft={help}>
            <SurveyTheme theme={wallRecord.themeTitle} />
            {wallRecord.options.map((option, index) => <SurveyOption key={index} text={option.option} />)}
            <hr className={classes.separator} />
            <PostComments count={wallRecord.commentsNum} />
        </ContentContainer>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: '20px',
        paddingLeft: '62px'
    },
    themeContainer: {
        fontSize: '14px',
        fontWeight: '400',
        color: '#5C5C5C',
        lineHeight: '21px',
        marginBottom: '10px'
    },
    optionContainer: {
        cursor: 'pointer',
        fontSize: '12px',
        fontWeight: '400',
        color: '#979797',
        height: '40px',
        lineHeight: '40px',
        borderRadius: '20px',
        backgroundColor: 'white',
        padding: '0 15px',
        marginBottom: '5px'
    },
    comments: {
        fontWeight: '600',
        fontSize: '10px',
        color: '#FF8106'
    },
    separator: {
        borderWidth: '0',
        borderBottom: '.5px solid #979797',
        marginBottom: 8,
        width: '100%'
    }
}));
