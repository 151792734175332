import React, { useState, useEffect } from "react";
import { Route } from "react-router";
import { Grid, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { HeaderContainerWeb } from "../components/Headers";
import { Menu } from "./../components/Menu";
import { Wall } from "./../components/Wall";
import { ComposePost } from "./../components/ComposePost";
import { Sidebar } from "../components/Sidebar";
import { InterestsPage } from "../pages/InterestsPage";
import ChatWindow from "../components/Chat";
import UserProfileEditPage from "./UserProfileEditPage";
import UserProfileReadOnlyPage from "./UserProfileReadOnlyPage";
import RequestFeaturePage from "./RequestFeaturePage";
import ColleaguesPage from "./ColleaguesPage";
import EventsPage from "./EventsPage";
import { useHistory } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

export const MainPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { authProvider, setAuthProvider, match, location } = props;
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [initialChatPosition, setInitialChatPosition] = useState({
    top: 55,
    left: 0,
  });
  const testUser = useAuthState(authProvider.fireBaseProvider.auth);

  const [state, setState] = useState({
    user: { interests: [] },
    interestCategories: [],
    events: [],
  });
  const [expandedCategoryId, setExpandedCategoryId] = useState(0);

  const onChangeFullName = (e) => {
    setState((s) => ({
      ...s,
      user: { ...s.user, fullName: e.target.value },
    }));
  };

  const onChangePosition = (e) => {
    setState((s) => ({
      ...s,
      user: { ...s.user, position: e.target.value },
    }));
  };

  const onChangeDateOfBirth = (e) => {
    setState((s) => ({
      ...s,
      user: { ...s.user, dateOfBirth: e },
    }));
  };

  const onChangeEmploymentDate = (e) => {
    setState((s) => ({
      ...s,
      user: { ...s.user, employmentDate: e },
    }));
  };

  const onChangeInterest = (interestId, isSelected, interestName) => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URI}/api/user/updateInterest?interestId=${interestId}&isSelected=${isSelected}`,
      {
        method: "POST",
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setState((s) => ({
            ...s,
            user: { ...s.user, interests: result },
          }));
          addToInterestConversation(interestId, isSelected, interestName);
        },
        (error) => {
          console.error(error);
        }
      );
  };

  const addToInterestConversation = (interestId, isSelected, interestName) => {
    const conversationsRef = authProvider.fireBaseProvider.firestore.collection(state.user.company + '/chat/conversations/');
    /*
    conversationsRef.where('name', '==', 'Interest: ' + interestName)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {

          doc.ref.update({
            participants: participants
          });
        });
      });
      */
    conversationsRef
      .doc('Interest_' + interestId)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          let conversation = doc.data();
          let currentParticipant = [state.user.externalId];
          let participants = conversation.participants;

          const index = participants.indexOf(state.user.externalId);
          if (index > -1 && !isSelected) {
            participants.splice(index, 1);
          }

          if (isSelected && !conversation.participants.includes(state.user.externalId)) {
            participants = conversation.participants.concat(currentParticipant);
          }        

          doc.ref.update({
            participants: participants
          });          
        }
        else {
          if (isSelected) {
            var res = await conversationsRef.doc('Interest_' + interestId).set({              
              name: 'Interest: ' + interestName,
              isOneToOne: false,
              participants: [state.user.externalId],
              updatedAt: authProvider.fireBaseProvider.db.firestore.FieldValue.serverTimestamp(),
              imgURL: "https://firebasestorage.googleapis.com/v0/b/wellbe-dd809.appspot.com/o/openMedia%2Finterests_default_color.png?alt=media&token=dc6672c9-f82a-48e5-aab8-2ddef0da7a83"
            });
          }
        }
      });
  };

  const onChangeAvatarImg = (e) => {
    setState((s) => ({
      ...s,
      user: { ...s.user, avatarImg: e.target.value },
    }));
  };

  const onUploadAvatarImg = (url) => {
    setState((s) => ({
      ...s,
      user: { ...s.user, avatarImg: url },
    }));
  };

  const loadPageData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URI}/api/userProfiles/current`, {
      credentials: "include",
    })
      .then((res) => {
        if (
          res.status !=
          200 /*|| authProvider.fireBaseProvider.auth.currentUser == undefined*/
        ) {
          history.push("/");
        }
        return res.json();
      })
      .then(
        (result) => {
          setState(result);
        },
        (error) => {
          console.error(error);
        }
      );
  };

  useEffect(loadPageData, []);

  const start = (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4} lg={5}>
        <Sidebar user={state.user} categories={state.interestCategories} expandCategoryHandler={setExpandedCategoryId} />
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={7}>
        <ComposePost authProvider={authProvider} user={state.user} />
        <Wall
          authProvider={authProvider}
          user={state.user}
          events={state.events}
        />
      </Grid>
    </Grid>
  );

  const interestsPage = (
    <InterestsPage
      user={state.user}
      categories={state.interestCategories}
      onChangeInterest={onChangeInterest}
      expandedCategoryId={expandedCategoryId}
      onCategoryExpanded={(id) =>
        setExpandedCategoryId(id === expandedCategoryId ? 0 : id)
      }
    />
  );

  const openChatHandler = (e) => {
    if (e != undefined) {
      var viewportOffset = e.target.getBoundingClientRect();
      setInitialChatPosition({
        top: viewportOffset.top + 36,
        left: viewportOffset.left - 360,
      });
    }
    setIsChatOpen(!isChatOpen);
  };

  const [newConversationWithColeague, setNewConversationWithColeague] = React.useState({ externalId: '', fullName: '', imgURL:'' });

  const openChatHandlerWithConversation = (conv) => {
    setNewConversationWithColeague(conv);
    if (!isChatOpen) {
      setIsChatOpen(true);
    }
  };

  return (
    <>
      <HeaderContainerWeb
        logo
        notification
        openChatHandler={openChatHandler}
        home={"/profile"}
        authProvider={authProvider}
        user={state.user}
      />
      <div className={classes.baseWeb}>
        <Grid container spacing={2}>
          <Grid item md={12} lg={10}>
            <Route path={match.url + "/"} exact component={() => start} />
            <Route path={match.url + "/edit"}>
              <UserProfileEditPage
                user={state.user}
                onChangeFullName={onChangeFullName}
                onChangePosition={onChangePosition}
                onChangeDateOfBirth={onChangeDateOfBirth}
                onChangeEmploymentDate={onChangeEmploymentDate}
                onChangeAvatarImg={onChangeAvatarImg}
                onUploadAvatarImg={onUploadAvatarImg}
                authProvider={authProvider}
              />
            </Route>
            <Route path={match.url + "/colleague"}>
              <UserProfileReadOnlyPage
                openChatHandlerWithConversation={
                  openChatHandlerWithConversation
                }
              />
            </Route>
            <Route path={match.url + "/newfeaturerequest"}>
              <RequestFeaturePage
                user={state.user}
                feature={location.search}
                authProvider={authProvider}
              />
            </Route>
            <Route path={match.url + "/events"}>
              <EventsPage user={state.user} />
            </Route>
            <Route path={match.url + "/colleagues"}>
              <ColleaguesPage user={state.user} openChatHandlerWithConversation={openChatHandlerWithConversation}/>
            </Route>
            <Route
              path={match.url + "/interests"}
              component={() => interestsPage}
            />
          </Grid>
          <Hidden only={["xs", "sm", "md"]}>
            <Grid item lg={2}>
              <Menu />
            </Grid>
          </Hidden>
        </Grid>
      </div>
      <div
        style={{
          position: "absolute",
          top: initialChatPosition.top + "px",
          left: initialChatPosition.left + "px",
          width: "0px",
          height: "0px",
        }}
      >
        <ChatWindow
          authProvider={authProvider}
          setAuthProvider={setAuthProvider}
          isChatOpen={isChatOpen}
          openChatHandler={openChatHandler}
          user={state.user}
          selectedConversation={newConversationWithColeague}
          selectConversationHandler={setNewConversationWithColeague}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  baseWeb: {
    maxWidth: "1280px",
    margin: "0 auto",
    marginTop: "40px",
  },
}));
