import { makeStyles } from "@material-ui/styles";

const dateOptions = {
  weekday: undefined,
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const Title = ({
  username,
  position,
  companyPrefix,
  company,
  dateOfBirth,
  employmentDate,
  numberOfYears,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.title}>
      <div className={classes.username}>{username}</div>
      {position && <div className={classes.position}>{position}</div>}
      {company && (
        <div className={classes.company}>
          {companyPrefix} {company}
        </div>
      )}
      {dateOfBirth && (
        <div className={classes.company}>
          Date of birth:{" "}
          {new Date(dateOfBirth).toLocaleDateString("en-US", dateOptions)}
        </div>
      )}
      {employmentDate && (
        <div className={classes.company}>
          Employment date:{" "}
          {new Date(employmentDate).toLocaleDateString("en-US", dateOptions)}
        </div>
      )}
      {numberOfYears && (
        <div className={classes.company}>
          Number of years has been in the company: {numberOfYears}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "14px",
    textAlign: "center",
  },
  username: {
    fontWeight: "500",
    fontSize: "20px",
    color: "black",
    marginBottom: "5px",
  },
  position: {
    fontWeight: "600",
    color: "#979797",
  },
  company: {
    fontWeight: "400",
    color: "#979797",
  },
}));
