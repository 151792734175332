import { makeStyles } from "@material-ui/styles";
import { ContentContainer } from "./ContentContainer";

const EventItem = ({ avatarSrc, fullname, action }) => {
  const classes = useStyles();

  return (
    <ContentContainer classes={{ root: classes.root }}>
      <div style={{ minWidth: "56px" }}>
        <img className={classes.avatar} src={avatarSrc} alt="" />
      </div>
      <div className={classes.content}>
        <div style={{ marginBottom: "8px" }}>
          <span className={classes.fullname}>{fullname}</span>
        </div>
        <div className={classes.action}>{action}</div>
      </div>
    </ContentContainer>
  );
};

export default EventItem;

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "20px",
    flexDirection: "row",
    padding: "8px",
    fontWeight: "400",
  },
  avatar: {
      borderRadius: "50%",
      width: "48px",
      height: "48px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  fullname: {
    fontSize: "14px",
    color: "#FF8106",
    marginRight: "15px",
  },
  action: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#979797",
    marginBottom: "8px",
  },
}));
