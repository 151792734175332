import { ContentContainer } from "./../components/ContentContainer";
import LinkBackToProfile from "../components/LinkBackToProfile";
import { UserProfile } from "./../components/UserProfile";
import { Grid } from "@material-ui/core";
import { UserInterestCard } from "../components/UserInterestCard";
import { makeStyles } from "@material-ui/core/styles";

const InterestsPageCurrentInterests = ({ user }) => {
  const classes = useStyles();

  return (
    <ContentContainer
      classes={{ root: classes.root }}
      controlTopLeft={<LinkBackToProfile />}
    >
      <UserProfile pictureSrc={user.avatarImg} username={user.name} />
      <div className={classes.subtitle}>Interests</div>
      {user.interests.length < 4 && <div className={classes.notification}>Please provide at least 4 of your interests to see others</div>}
      {user.interests.length > 0 && <Grid container spacing={1}>
        {user.interests.map((userInterest, index) => (
          <Grid key={index} item xs={6} lg={3}>
            <UserInterestCard
              key={userInterest.id.toString()}
              userInterest={userInterest}
            />
          </Grid>
        ))}
      </Grid>}      
    </ContentContainer>
  );
};

export default InterestsPageCurrentInterests;

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20px",
  },
  subtitle: {
    fontSize: "16",
    fontWeight: "400",
    color: "#979797",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "30px",
  },
  notification: {
    fontSize: "16",
    fontWeight: "400",
    color: "#979797",
    textAlign: "center",
    marginTop: "0px",
    marginBottom: "10px",
  },
  interest: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "10px",
    color: "#979797",
    backgroundColor: "white",
    height: "130px",
    padding: "10px",
    position: "relative",
    userSelect: "none",
    marginTop: "8px",
  },
  interestHeader: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "16px",
  },
  iterestHeaderChecked: {
    color: "#292929",
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  interestChecker: {
    cursor: "pointer",
    width: "16px",
    height: "16px",
  },
  avatarsDetails: {
    marginTop: "8px",
  },
  avatarDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#5C5C5C",
    fontSize: "10px",
  },
}));
