import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import checkIcon from './../assets/check_icon.svg';

export const Checkbox = ({ checked, onClick, className }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.button, className, checked && classes.checked)} onClick={onClick}>
            <img src={checkIcon} />
        </div>
    );
};

const useStyles = makeStyles(() => ({
    button: {
        display: 'flex',
        backgroundColor: 'transparent',
        border: '1px solid #FF8106',
        borderRadius: '50%',
        justifyContent: 'center',
        userSelect: 'none'
    },
    checked: {
        borderWidth: 0,
        backgroundColor: '#36FC85',
    }
}));
