import { Collapse, Grid } from "@material-ui/core";
import { UserInterestCard } from "../components/UserInterestCard";
import InterestCategoryItem from "../components/InterestCategoryItem";
import React, { useState, useEffect } from "react";

const InterestCategoryGridItem = ({
    category,
    selectedInterests,
    onChangeInterest,
    expanded,
    onExpanded,
    user,
}) => {

  const [userProfiles, setUserProfiles] = useState([]);  
  
  const interestForFilter = category.interests.map(function (obj) {
    return obj.name;
  });

  const filtered = selectedInterests.filter(function (value, index, arr) {
    return interestForFilter.includes(value.name);
  });

  const showProfiles = (filtered.length !== 0);
     
  useEffect(() => {
      fetch(`${process.env.REACT_APP_API_BASE_URI}/api/current/companyProfiles`, {
          credentials: "include",
      })
          .then((res) => res.json())
          .then(
              (result) => {
                  /*var filtered = result.filter(function (user) {
                    return user.interests.name == user.interests.name;
                  });*/
                  setUserProfiles(result);
                  //setCurrentUserName(userName);
              },
              (error) => { }
          );
  }, [user]);

  return(
      <Grid item>
          <UserInterestCard
              userInterest={category}
              onClick={() => onExpanded(category.id)}
              expanded={expanded}
              expandable={true}
              height={"100px"}
          />
          <Collapse in={expanded}>
              {category.interests.map((interest, index) => (
                  <InterestCategoryItem
                  key={index}
                  selectedInterests={selectedInterests}
                  interest={interest}
                  onChange={onChangeInterest}
                  userProfiles={userProfiles}
                  showProfiles={showProfiles}
                  />
              ))}
          </Collapse>
      </Grid>
  )
};

export default InterestCategoryGridItem;
