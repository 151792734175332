import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { SvgIcon } from "./SvgIcon";
import { ExpanderButton } from "./ExpanderButton";
import { UserProfile } from "./../components/UserProfile";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Title } from "./../components/Title";
import Button from "@material-ui/core/Button";

export const UserProfileCard = ({
  profile,
  onClick,
  expanded,
  height = "100%",
  openChatHandlerWithConversation,
  user
}) => {
  const classes = useStyles();
  const [top4Interest, setTop4Interest] = useState([]);

  useEffect(() => {
    setTop4Interest (profile.interests.filter(function (value, index, arr) {
      return JSON.stringify(user.interests).indexOf(JSON.stringify(value)) > 0;
    }).slice(0,4));
  }, [user]);

  return (
    <div
      className={classes.root}
      style={{ borderColor: "white", height: height }}
    >
      <UserProfile
        pictureSrc={profile.avatarImg}
        profileLink={
          <>
            <Link
              className={classes.textDecorationLine}
              id="efewfefef"
              to={{
                pathname: "/profile/colleague",
                state: profile,
              }}
            >
              <Title
                username={profile.fullName}
                position={profile.position}
                companyPrefix="in"
                company={profile.company}
              />
            </Link>
            <Button
              type="button"
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={() => openChatHandlerWithConversation({ externalId: profile.externalId, fullName: profile.fullName, imgURL: profile.avatarImg })}
            >
              Start conversation
            </Button>
          </>
        }
        dateOfBirth={profile.dateOfBirth}
      />
      <p />
      {!expanded && (
        <Grid container spacing={2}>
          {top4Interest.map((interest, index) => (
            <Grid key={index} item xs={12} lg={12}>
              <div title={interest.name}>
                <SvgIcon
                  icon={interest.icon}
                  size={"16px"}
                  color={interest.color}
                />
                <span style={{ paddingLeft: "10px" }}>{interest.name}</span>
              </div>
            </Grid>
          ))}
        </Grid>
      )}
      {expanded && (
        <Grid container spacing={2}>
          {profile.interests.map((interest, index) => (
            <Grid key={index} item xs={12} lg={12}>
              <div title={interest.name}>
                <SvgIcon
                  icon={interest.icon}
                  size={"16px"}
                  color={interest.color}
                />
                <span style={{ paddingLeft: "10px" }}>{interest.name}</span>
              </div>
            </Grid>
          ))}
        </Grid>
      )}
      {false && onClick && (
        <div onClick={onClick}>
          <ExpanderButton expanded={expanded} />
        </div>
      )}
    </div>
  );
};

export const useStyles = makeStyles(() => ({
  root: {
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: "white",
    color: "#979797",
    borderRadius: "10px",
    borderWidth: "2px",
    borderStyle: "solid",
    userSelect: "none",
    padding: "5px",
  },
  collegueProfileLink: {
    textDecorationLine: "none",
  },
  submit: {
    width: "100%",
    marginTop: "1.5vw",
    height: "2.5em",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
  },
}));
