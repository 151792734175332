import React from "react";
import { HeaderContainerWeb } from "../components/Headers";
import RegisterForm from "../components/RegisterForm";

const RegisterPage = ({ authProvider }) => {
  return (
    <>
      <HeaderContainerWeb logo authProvider={authProvider}/>
      <RegisterForm authProvider={authProvider} />
    </>
  );
};

export default RegisterPage;
