import React from "react";
import { HeaderContainerWeb } from "../Headers";
import DailySurveyBody from "./daily_survey_body";

const DailySurvey = (props) => {
  const { authProvider } = props;
  return (
    <>
      <HeaderContainerWeb logo notification authProvider={authProvider}/>
      <DailySurveyBody />
    </>
  );
};

export default DailySurvey;
