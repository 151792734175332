import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import { useStyles } from "./styles";
import DailySurveyQuestion from "./DailySurveyQuestion";
import { makeStyles } from "@material-ui/core/styles";

function DailySurveyBody({ history }) {
  const [survey, setSurvey] = useState({ questions: [] });
  const [submitEnabled, setSubmitEnabled] = useState(false);

  useEffect(
    () =>
      fetch(`${process.env.REACT_APP_API_BASE_URI}/api/dailySurveys/current`, {
        method: "GET",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.questions) setSurvey(data);
          else history.push("/profile");
        })
        .catch((error) => {
          console.error("Error:", error);
        }),
    []
  );

  const classes = useStyles();

  const onSubmit = async (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_BASE_URI}/api/dailySurveyUserReponses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        answerIds: survey.questions
          .map((x) => x.availableAnswers)
          .flat()
          .filter((x) => x.checked)
          .map((x) => x.id),
      }),
    })
      .then((response) => {
        if (!response.ok) console.error("Unknown error happened");
        else history.push("/profile");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onClick = (id) => {
    const questions = survey.questions.map((question) => {
      question.availableAnswers = question.availableAnswers.map((answer) => {
        setSubmitEnabled(true);
        if (answer.id == id) answer.checked = true;
        else answer.checked = false;
        return answer;
      });
      return question;
    });
    setSurvey((s) => ({ ...s, questions }));
  };

  const questions = (questions) =>
    questions.map((element, index) => (
      <DailySurveyQuestion
        key={element.id}
        question={element}
        onClick={onClick}
      />
    ));

  const questionFormClasses = makeStyles({
    root: { display: "flex", flexDirection: "column", alignItems: "center" },
  })();

  return (
    <>
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.title}>Daily survey</div>
        <form onSubmit={onSubmit} className={questionFormClasses.root}>
          {questions(survey.questions)}
          <div className={classes.submitButtonContainer}>
            <Button
              type="submit"
              variant="contained"
              disabled={!submitEnabled}
              className={classes.submitButton}
            >
              Sent
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default withRouter(DailySurveyBody);
