import { makeStyles } from "@material-ui/styles";
import { ContentContainer } from "./ContentContainer";
import React, { useState } from "react";
import add from "./../assets/center_column/change_status_add_photo_icon.svg";
import paperclip from "./../assets/center_column/paper_clip.svg";
import ButtonMUI from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";

const Button = ({ icon, sendMessageHandler }) => {
  const classes = useStyles();

  return (
    <div className={classes.button} onClick={sendMessageHandler}>
      <img src={icon} alt="" />
    </div>
  );
};

const EventListItem = (props) => {
  const data = props.data;
  const user = props.user;
  const classes = useStyles();
  const [formValue, setFormValue] = useState("");
  const { auth, firestore, db } = props.authProvider.fireBaseProvider;
  const postsRef = firestore.collection("wall/" + user.company + "/posts");
  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL } = auth;

    await postsRef.add({
      recordType: "basicPost",
      userName: user.fullName,
      avatarSmall: user.avatarImg,
      altUserAvatar: "user avatar",
      userProfileLink: "#",
      jobTitle: user.position,
      dateCreated: db.firestore.FieldValue.serverTimestamp(),
      addedPhotos: "",
      contentText: formValue,
      userEmail: auth.currentUser.email,
      contentPhoto: [],
    });

    setFormValue("");
  };

  return (
    <ContentContainer classes={{ root: classes.root }}>
      <div style={{ minWidth: "56px" }}>
        <img className={classes.avatar} src={data.titleImagePath} alt="" />
      </div>
      <div className={classes.content}>
        <div style={{ marginBottom: "8px" }}>
          <span className={classes.fullname}>{data.owner.fullName}</span>
        </div>
        <div className={classes.action}>{data.description}</div>
        <div className={classes.inputRow}>
          <textarea
            rows="1"
            className={classes.sendMessageForm + " scrollable"}
            style={{ width: "100%", resize: "none" }}
            value={formValue}
            onChange={(e) => setFormValue(e.target.value)}
            placeholder="Write on the dashboard..."
            id="messageInput"
          />
          <ButtonMUI
            onClick={sendMessage}
            disabled={!formValue}
            className={classes.sendButton}
          >
            <SendIcon />
          </ButtonMUI>
        </div>
      </div>
    </ContentContainer>
  );
};

export default EventListItem;

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "20px",
    flexDirection: "row",
    padding: "8px",
    fontWeight: "400",
  },
  avatar: {
    borderRadius: "50%",
    width: "48px",
    height: "48px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
  },
  fullname: {
    fontSize: "14px",
    color: "#FF8106",
    marginRight: "15px",
  },
  action: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#979797",
    marginBottom: "8px",
  },
  inputRow: {
    display: "flex",
    flexDirection: "row",
  },
}));
