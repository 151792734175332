import React, { useState } from "react";
import { Switch, Route } from "react-router";
import { GlobalStyles } from "./global_styles";
import Login from "./login_page/";
import RegisterPage from "../pages/RegisterPage";
import DailySurvey from "./daily_survey_page";
import { MainPage } from "./../pages/MainPage";
import SelectCompanyPage from "../pages/SelectCompanyPage";
import CreateCompanyPage from "../pages/CreateCompanyPage";
import InvitePage from "../pages/InvitePage";
import SelectProfilePage from "../pages/SelectProfilePage";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { useAuthState } from 'react-firebase-hooks/auth';

firebase.initializeApp({
    apiKey: "AIzaSyALxrnt59NgM4emgG_qjweJhunOOt-__wc",
    authDomain: "wellbe-dd809.firebaseapp.com",
    projectId: "wellbe-dd809",
    storageBucket: "wellbe-dd809.appspot.com",
    messagingSenderId: "571382342641",
    appId: "1:571382342641:web:d01ed874881c765850da50",
    measurementId: "G-27X8E9YPSL"
})

const App = () => {
  const [authProvider, setAuthProvider] = useState({ fireBaseProvider: { auth: firebase.auth(), firestore: firebase.firestore(), db: firebase } });

  return (
    <>
      <GlobalStyles />      
      <Switch>
        <Route path="/" exact={true}>
          <Login authProvider={authProvider} setAuthProvider={()=>setAuthProvider()}/>
        </Route>
        <Route path="/register">
          <RegisterPage authProvider={authProvider}/>
        </Route>
        <Route path="/selectCompany">
          <SelectCompanyPage authProvider={authProvider}/>
        </Route>
        <Route path="/selectProfile">
          <SelectProfilePage authProvider={authProvider}/>
        </Route>
        <Route path="/createCompany">
          <CreateCompanyPage authProvider={authProvider}/>
        </Route>
        <Route path="/invite">
          <InvitePage authProvider={authProvider}/>
        </Route>
        <Route path="/survey">
          <DailySurvey authProvider={authProvider} />
        </Route>
              <Route path="/profile" render={(props) => (<MainPage {...props} authProvider={authProvider} setAuthProvider={() => setAuthProvider()} />)}/>
      </Switch>      
    </>
  );
};

export default App;
