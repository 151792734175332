import hatPicture from "./../assets/left_column/party-hat.svg";
import { Badge, makeStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const StatusIndicator = withStyles({
  root: {
    "& .MuiBadge-root": {
      width: "160px",
      height: "160px",
    },
    "& .MuiBadge-colorSecondary": {
      backgroundColor: "#36FC85",
    },
    "& .MuiBadge-dot": {
      height: "16px",
      width: "16px",
    },
    "& .MuiBadge-badge": {
      borderRadius: "50%",
    },
  },
})(Badge);

const Picture = ({ src, hat }) => {
  const classes = useStyles();

  return (
    <div className={classes.picture}>
      {hat && <img className={classes.hat} src={hatPicture} alt="" />}
      <img className={classes.rounded} src={src} alt="" />
    </div>
  );
};

export const UserProfile = ({ pictureSrc, profileLink, dateOfBirth }) => {
  const classes = useStyles();
  var isBirthday = false;
  /*
  if (dateOfBirth != undefined) {
    const birthdate = new Date(dateOfBirth);
    isBirthday = (birthdate.getDate() == Date.now().getDate() && birthdate.getMonth() == Date.now().getMonth());
    var t = 1;
  }*/
  return (
    <div className={classes.root}>
      <StatusIndicator color="secondary" variant="dot" overlap="circle">
        <Picture src={pictureSrc} hat={isBirthday} indicator />
      </StatusIndicator>
      {profileLink}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "45px",
  },
  picture: {
    position: "relative",
    width: "160px",
    height: "160px",
  },
  rounded: {
    borderRadius: "50%",
  },
  hat: {
    position: "absolute",
    right: "50%",
    top: "0px",
    zIndex: "1",
    transform: "translate(50%, -90%)",
  },
}));
