import React, { useMemo } from "react";
import colleagues from "./../assets/right_menu/friends.svg";
import event from "./../assets/right_menu/events.svg";
import dashboard from "./../assets/right_menu/dashboard.svg";
import photo from "./../assets/right_menu/photo_gallery.svg";
import online from "./../assets/right_menu/online_parties.svg";
import discussion from "./../assets/right_menu/discussion.svg";
import c_level from "./../assets/right_menu/c_level_coffee.svg";
import presents from "./../assets/right_menu/presents.svg";
import dash from "./../assets/right_menu/dash_games.svg";
import challenges from "./../assets/right_menu/challenges.svg";
import sport from "./../assets/right_menu/sport.svg";
import congrats from "./../assets/right_menu/congratulatory.svg";
import profile from "./../assets/right_menu/profile.svg";
import { ContentContainer } from "./ContentContainer";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const MenuItem = ({ icon, text, address }) => {
  const classes = useStyles();

  return (
    <div className={classes.menuItemRoot}>
      <div className={classes.menuItemIcon}>
        <img src={icon} alt="" />
      </div>
      <Link to={address || "#"} style={{ textDecoration: "none" }}>
        <span className={classes.menuItemText}>{text}</span>
      </Link>
    </div>
  );
};

export const Menu = () => {
  const classes = useStyles();

  const items = useMemo(() => {
    return [
      {
        img: colleagues,
        text: "Colleagues",
        address: "/profile/colleagues",
      },
      {
        img: event,
        text: "Calendar",
        address: "/profile/events",
      },
      {
        img: dashboard,
        text: "Dashboard",
        address: "/profile",
      },
      {
        img: photo,
        text: "Photo Gallery",
        address: "/profile/newfeaturerequest?Photo Gallery",
      },
      {
        img: online,
        text: "Online parties",
        address: "/profile/newfeaturerequest?Online parties",
      },
      /*{
        img: discussion,
        text: "Discussion clubs",
        address: "/profile/newfeaturerequest?Discussion clubs",
      },*/
      {
        img: c_level,
        text: "Coffee breaks",
        address: "/profile/newfeaturerequest?Coffee breaks",
      },
      {
        img: presents,
        text: "Kudos",
        address: "/profile/newfeaturerequest?Kudos",
      },
      /*{
        img: dash,
        text: "Dash games",
        address: "/profile/newfeaturerequest?Dash games",
      },*/
      {
        img: challenges,
        text: "Challenges",
        address: "/profile/newfeaturerequest?Challenges",
      },
      {
        img: sport,
        text: "Sport online activities",
        address: "/profile/newfeaturerequest?Sport online activities",
      },
      {
        img: congrats,
        text: "Greeting video",
        address: "/profile/newfeaturerequest?Greeting video",
      },
      {
        img: profile,
        text: "Profile settings",
        address: '/profile/edit'
      },
    ];
  }, []);

  return (
    <ContentContainer classes={{ root: classes.root }}>
      {items.map((item, index) => (
        <MenuItem
          key={index}
          icon={item.img}
          text={item.text}
          address={item.address}
        />
      ))}
    </ContentContainer>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    padding: "0 10px",
  },
  menuItemText: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#979797",
  },
  menuItemRoot: {
    display: "flex",
    alignItems: "center",
    height: "50px",
  },
  menuItemIcon: {
    width: "30px",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
  },
}));
