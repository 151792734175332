import withStyles from "@material-ui/core/styles/withStyles";
import Badge from "@material-ui/core/Badge";


export const SmallDotBadge = withStyles({
    root: {
        '& .MuiBadge-colorSecondary': {
            backgroundColor:'#FF8106',
        },
        '& .MuiBadge-dot': {
            height: '4px',
            minWidth: '4px'
        }
    },
})(Badge);

export const BigDotBadge = withStyles({
    root: {
        '& .MuiBadge-colorSecondary': {
            backgroundColor:'#FF8106',
        },
        '& .MuiBadge-anchorOriginTopRightRectangle': {
            transform: 'scale(1) translate(80%, -130%)',
        }
    },
})(Badge);