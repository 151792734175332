import { useStyles } from "./styles";
import { makeStyles } from "@material-ui/core";
import { Checkbox } from "./../../components/Checkbox";
import happy from "../../assets/happy.gif";
import smile from "../../assets/smile.gif";
import neutral from "../../assets/neutral.gif";
import cry from "../../assets/cry.gif";

const DailySurveyAnswer = ({ answer, onClick }) => {
  const classes = useStyles();
  const localClasses = useLocalStyles();

  const moods = [
    {
      alt: "happy",
      img: happy,
    },
    {
      alt: "smile",
      img: smile,
    },
    {
      alt: "neutral",
      img: neutral,
    },
    {
      alt: "cry",
      img: cry,
    },
  ];

  return (
    <>
      <div key={answer.id}>
        <img
          src={moods.find((x) => x.alt == answer.name).img}
          alt={answer.text}
          className={classes.moodAnimation}
        />
        <div className={classes.checkbox}>
          <Checkbox
            className={localClasses.checker}
            checked={answer.checked}
            onClick={() => onClick(answer.id)}
          />
        </div>
      </div>
    </>
  );
};

export default DailySurveyAnswer;

const useLocalStyles = makeStyles(() => ({
  checker: {
    width: "24px",
    height: "24px",
  },
}));
