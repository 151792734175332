import arrowDown from './../assets/arrow-down.svg';
import arrowUp from './../assets/arrow-up.svg';
import { makeStyles } from "@material-ui/styles";

export const ExpanderButton = ({ expanded, onClick }) => {
    const classes = useStyles();

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div onClick={onClick} className={classes.expander}>
                <img src={expanded ? arrowUp : arrowDown} alt='' />
            </div>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    expander: {
        display: 'flex',
        backgroundColor: 'white',
        width: '24px',
        height: '24px',
        justifyContent: 'center',
        alignContent: 'center',
        borderRadius: '50%',
        marginTop: '15px',
        cursor: "pointer",
    }
}));
