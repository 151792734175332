import React from "react";
import { makeStyles } from "@material-ui/styles";
import { SmallDotBadge } from "./Badges";
import logo from "../assets/group26.svg";
import frame from "../assets/frame.svg";
import notif3 from "../assets/notif_3.svg";
import { AppBar } from "@material-ui/core";
import { SvgIcon } from "../components/SvgIcon";
import { useHistory } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useCollectionData } from 'react-firebase-hooks/firestore';


export const useStyles = makeStyles(() => ({
  white_container: {
    width: "100%",
    paddingTop: "1em",
    paddingBottom: "1em",
    backgroundColor: "#FFFFFF",
  },
  flexContainer: {
    maxWidth: "1280px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
  },
  notifications: {
    display: "flex",
    alignItems: "center",
    "& a": {
      margin: "0 5px",
    },
  },
  chat: {
    position: "relative",
    cursor: "pointer",
  },
  container: {
    display: "flex",
    position: "absolute",
    width: "24px",
    height: "24px",
    top: 0,
    left: 0,
    fontSize: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  pointer: {
    cursor: "pointer",
  },
}));

export const HeaderContainerWeb = ({
  logo,
  notification,
  openChatHandler,
  home,
  authProvider,
  user
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { firestore } = authProvider.fireBaseProvider;

  const goBackHandler = () => {
    if (home != undefined) {
      history.push(home);
    }
  };

  const logOutHandler = () => {
    authProvider.fireBaseProvider.auth.signOut().then(() => {
      fetch(`${process.env.REACT_APP_API_BASE_URI}/api/account/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }).then(() => history.push("/"));
    });
  };

  return (
    <AppBar position="sticky" elevation={0}>
      <div className={classes.white_container}>
        <div className={classes.flexContainer}>
          {logo && <HeaderLogoWeb goBackHandler={goBackHandler} />}
          {notification && user && (
            <HeaderNotificationsWeb
              openChatHandler={openChatHandler}
              logOutHandler={logOutHandler}
              firestore={firestore}
              appUser={user}
            />
          )}
        </div>
      </div>
    </AppBar>
  );
};

export const HeaderLogoWeb = ({ goBackHandler }) => {
  const classes = useStyles();
  return (
    <div onClick={goBackHandler} className={classes.pointer}>
      <img src={logo} alt="logo1" />
      <img src={frame} alt="logo2" />
    </div>
  );
};

export const HeaderNotificationsWeb = (props) => {
  const classes = useStyles();
  const openChatHandler = props.openChatHandler;
  const logOutHandler = props.logOutHandler;
  const appUser = props.appUser;
  const firestore = props.firestore

  const unreadMessagesRef = firestore.collection(appUser.company + '/chat/' + appUser.externalId + 'unreadMessages/');
  const [unreadMessages] = useCollectionData(unreadMessagesRef, { idField: 'id' });
  const messagesFromMAnagement = false;

  return (
    <div className={classes.notifications}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "53px",
        }}
      >
        {messagesFromMAnagement &&
          <div className={classes.chat}>
            <div>
              <SvgIcon icon={"chat"} size={"24px"} color={"#A61A1A"} />
              <div className={classes.container}>2</div>
            </div>
          </div>}
        <div className={classes.chat} onClick={(e) => openChatHandler(e)}>
          <div>
            <SvgIcon icon={"chat"} size={"24px"} color={"#FF8106"} />
            <div className={classes.container}>{(unreadMessages !== undefined) ? unreadMessages.length : 0}</div>
          </div>
        </div>
        <div
          style={{ margin: "2px", cursor: "pointer" }}
          title="Logout"
          onClick={() => logOutHandler()}
        >
          <ExitToAppIcon style={{ color: "darkorange" }} title="Logout" />
        </div>
      </div>

    </div>
  );
};
