import { withStyles } from "@material-ui/styles";

const root = {
  padding: "20px 15px",
  backgroundColor: "rgba(255, 255, 255, .8)",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  position: "relative",
};

const title = {
  marginBottom: "20px",
  fontWeight: 400,
  color: "#979797",
  textAlign: "center",
};

export const ContentContainer = withStyles({ root: root, title: title })(
  ({ classes, title, children, control, controlTopLeft }) => {
    return (
      <div className={classes.root}>
        {control && (
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              width: "24px",
              height: "24px",
            }}
          >
            {control}
          </div>
        )}
        {controlTopLeft && (
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              width: "24px",
              height: "24px",
            }}
          >
            {controlTopLeft}
          </div>
        )}
        {title && <div className={classes.title}>{title}</div>}
        {children}
      </div>
    );
  }
);
