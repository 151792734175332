import { UserProfile } from "./../components/UserProfile";
import { ContentContainer } from "./../components/ContentContainer";
import { Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { CssTextField } from "../components/CssTextField";
import { CssDatePicker } from "../components/CssDatePicker";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import LinkBackToProfile from "../components/LinkBackToProfile";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FileUploader from "react-firebase-file-uploader";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const UserProfileEditPage = ({
  history,
  user,
  onChangeFullName,
  onChangePosition,
  onChangeDateOfBirth,
  onChangeEmploymentDate,
  onChangeAvatarImg,
  onUploadAvatarImg,
  authProvider,
}) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [serverError, setServerError] = useState([]);
  const { auth, firestore, db } = authProvider.fireBaseProvider;

  const onProfileSubmit = async (e) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_API_BASE_URI}/api/currentUserProfile/update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName: user.fullName,
          position: user.position,
          dateOfBirth: user.dateOfBirth,
          employmentDate: user.employmentDate,
          avatarImg: user.avatarImg,
        }),
        credentials: "include",
      }
    )
      .then((response) => {
        if (!response.ok) setServerError("Unknown error happened");
        else history.push("/profile");
      })
      .catch((error) => {
        setServerError("Unknown error happened");
        console.error("Error:", error);
      });
  };
  const onInviteSubmit = async (e) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_API_BASE_URI}/api/inviteColegue?email=${email}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    )
      .then((response) => {
        if (!response.ok) setServerError("Unknown error happened");
        else history.push("/profile");
      })
      .catch((error) => {
        setServerError("Unknown error happened");
        console.error("Error:", error);
      });
  };
  const onEmailChange = async (e) => {
    setEmail(e.target.value);
  };
  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: { main: "#ff8106" },
    },
  });

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [avatar, setAvatar] = useState("");
  /*const [avatarURL, setAvatarURL] = useState("");*/

  const handleUploadStart = () => {
    setUploadProgress(0);
    setIsUploading(true);
  };
  const handleProgress = (progress) => {
    setUploadProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
    console.error(error);
  };

  const handleUploadSuccess = (filename) => {
    setAvatar(filename);
    setUploadProgress(100);
    setIsUploading(false);

    db.storage()
      .ref("openMedia/" + user.company)
      .child(filename)
      .getDownloadURL()
      .then((url) => onUploadAvatarImg(url));
  };

  return (
    <>
      <ContentContainer
        classes={{ root: classes.root }}
        controlTopLeft={<LinkBackToProfile />}
      >
        <UserProfile pictureSrc={user.avatarImg} username={user.fullName} />
        <CssBaseline />
        <Grid container spacing={4} justify="center">
          <Grid item lg={3} sm={3} xs={12}>
            <div className={classes.serverError}>{serverError}</div>
            <form className={classes.form} onSubmit={onProfileSubmit}>
              <CssTextField
                className={classes.textField}
                onChange={onChangeFullName}
                value={user.fullName}
                error={!!user.fullName}
                helperText={""}
                variant="outlined"
                margin="normal"
                id="fullName"
                label="Name"
                name="fullName"
                autoComplete="name"
                autoFocus
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <CssTextField
                className={classes.textField}
                onChange={onChangePosition}
                value={user.position}
                error={!!user.position}
                helperText={""}
                variant="outlined"
                margin="normal"
                id="position"
                label="Job title"
                name="position"
                autoComplete="jobtitle"
                autoFocus
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <CssTextField
                className={classes.textField}
                onChange={onChangeAvatarImg}
                value={user.avatarImg}
                error={!!user.avatarImg}
                helperText={""}
                variant="outlined"
                margin="normal"
                id="avatarImg"
                label="URL to avatar"
                name="avatarImg"
                autoComplete="avatarImg"
                autoFocus
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <label className={classes.upload}>
                Upload your avatar
                <FileUploader
                  accept="image/*"
                  name="avatar"
                  hidden
                  randomizeFilename
                  storageRef={db.storage().ref("openMedia/" + user.company)}
                  onUploadSuccess={handleUploadSuccess}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onProgress={handleProgress}
                />
              </label>
              <CssTextField
                className={classes.textField}
                value={user.company}
                error={!!user.company}
                helperText={""}
                variant="outlined"
                margin="normal"
                id="company"
                label="Company"
                name="company"
                autoComplete="company"
                autoFocus
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <CssDatePicker
                    disableFuture
                    clearable
                    openTo="year"
                    format="dd/MM/yyyy"
                    label="Date of birth"
                    views={["year", "month", "date"]}
                    value={user.dateOfBirth}
                    onChange={onChangeDateOfBirth}
                    className={classes.textField}
                    inputVariant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <CssDatePicker
                    disableFuture
                    clearable
                    openTo="year"
                    format="dd/MM/yyyy"
                    label="Employment date"
                    views={["year", "month", "date"]}
                    value={user.employmentDate}
                    onChange={onChangeEmploymentDate}
                    className={classes.textField}
                    inputVariant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Confirm
              </Button>
            </form>
          </Grid>
          <Grid item lg={3} sm={3} xs={12}>
            <Typography variant="body1">Invite colleague</Typography>
            <form className={classes.form} onSubmit={onInviteSubmit}>
              <CssTextField
                className={classes.textField}
                onChange={onEmailChange}
                value={email}
                error={!!email}
                helperText={""}
                variant="outlined"
                margin="normal"
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Invite
              </Button>
            </form>
          </Grid>
        </Grid>
      </ContentContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20px",
  },
  textField: {
    display: "flex",
    alignItems: "center",
  },
  submit: {
    width: "100%",
    marginTop: "1.5vw",
    height: "2.5em",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
  },
  upload: {
    width: "85%",
    marginLeft: "7.5%",
    height: "2.5em",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    display: "block",
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "2.5em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
    cursor: "pointer",
  },
}));

export default withRouter(UserProfileEditPage);
