import InterestsPageCurrentInterests from "../components/InterestsPageCurrentInterests";
import InterestsPageAllInterests from "../components/InterestsPageAllInterests";

export const InterestsPage = ({
  user,
  categories,
  onChangeInterest,
  expandedCategoryId,
  onCategoryExpanded,
}) => (
  <>
    <InterestsPageCurrentInterests user={user} />
    <InterestsPageAllInterests
      selectedInterests={user.interests}
      categories={categories}
      onChangeInterest={onChangeInterest}
      expandedCategoryId={expandedCategoryId}
      onCategoryExpanded={onCategoryExpanded}
    />
  </>
);
