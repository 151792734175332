import { Link } from "react-router-dom";
import backIcon from "./../assets/arrow-left.svg";

const LinkBackToColleagues = () => (
  <Link to="/profile/colleagues">
    <img src={backIcon} alt="" />
  </Link>
);

export default LinkBackToColleagues;
