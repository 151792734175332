import EventListItem from "./EventListItem";

const EventList = (props) => {
  const events = props.events;

  if (events.length) {
    return events.map((event, index) => (
      <EventListItem
        data={event}
        key={index}
        authProvider={props.authProvider}
        user={props.user}
      />
    ));
  } else return null;
};

export default EventList;
