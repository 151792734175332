import React, { useEffect, useState } from "react";
import { HeaderContainerWeb } from "../components/Headers";
import SelectCompanyForm from "../components/SelectCompanyForm";

const SelectCompanyPage = ({ authProvider }) => {
  const [companies, setCompanies] = useState([]);

  useEffect(
    () =>
      fetch(`${process.env.REACT_APP_API_BASE_URI}/api/entityAccounts`, {
        method: "GET",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          setCompanies(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        }),
    []
  );

  return (
    <>
      <HeaderContainerWeb logo authProvider={authProvider}/>
      <SelectCompanyForm companies={companies} />
    </>
  );
};

export default SelectCompanyPage;
