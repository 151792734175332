import React, { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { CssTextField, useStyles } from "./styles";

function LoginBody(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [errUser, setErrUser] = useState("");
  const { authProvider, setAuthProvider, history } = props;

  const handleEmailChange = useCallback(
    (event) => {
      const email = event.target.value;
      setEmail(email);
      setErrEmail("");
      setErrUser("");
    },
    [setEmail]
  );

  const handlePasswordChange = useCallback(
    (event) => {
      const password = event.target.value;
      setPassword(password);
      setErrPassword("");
      setErrUser("");
    },
    [setPassword]
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrEmail("");
    setErrPassword("");
    setErrUser("");

    fetch(`${process.env.REACT_APP_API_BASE_URI}/api/account/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        authProvider.fireBaseProvider.auth
          .signInWithEmailAndPassword(email, password)
          .then(() => {
            if (result.profilesCount === 1) {
              history.push("/survey");
            } else {
              history.push("/selectProfile");
            }
          });
      })
      .catch((error) => {
        setErrUser("Unknown error happened");
        console.error("Error:", error);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.dbError}>{errUser}</div>
        <div className={classes.login}>Login</div>
        <form className={classes.form} onSubmit={onSubmit}>
          <CssTextField
            className={classes.textField}
            onChange={handleEmailChange}
            value={email}
            error={!!errEmail}
            helperText={errEmail ? errEmail : ""}
            variant="outlined"
            margin="normal"
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
          />
          <CssTextField
            className={classes.textField}
            onChange={handlePasswordChange}
            value={password}
            error={!!errPassword}
            helperText={errPassword ? errPassword : ""}
            variant="outlined"
            margin="normal"
            name="password"
            label="Password"
            type="password"
            id="password"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            Log in
          </Button>
          <Link to="/register" variant="body2" className={classes.link}>
            Register
          </Link>
        </form>
      </div>
    </Container>
  );
}

export default withRouter(LoginBody);
