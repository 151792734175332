import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  body {
    min-width: 350px;
    height: 100vh;
    margin: 0 auto;
    padding: 0;
    background: linear-gradient(#f7f7f7, #f2f2f2, #ffead4);
    background-attachment: fixed;
    font-family: 'Open Sans';
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  a {
    text-decoration: none
  }
`;
