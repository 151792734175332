import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const CssTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      fontSize: "0.8em",
      "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active":
        {
          "-webkit-box-shadow": "0 0 0 30px white inset !important",
        },
    },
    "& label.MuiFormLabel-root": {
      color: "#FF8106",
      fontSize: "1em",
      marginLeft: "25px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "3em",
      height: "2.3em",
      width: "85%",
      "& fieldset": {
        borderColor: "#DEE2E6",
      },
      "&:hover fieldset": {
        borderColor: "#DEE2E6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#DEE2E6",
      },
    },
  },
})(TextField);
