import { makeStyles } from "@material-ui/core/styles";
import { ContentContainer } from "./../components/ContentContainer";
import { UserProfile } from "./../components/UserProfile";
import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import LinkBackToProfile from "../components/LinkBackToProfile";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import EventItem from "../components/EventItem";

const EventsPage = (props) => {
  const classes = useStyles();
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState([]);

  useEffect(
    () =>
      fetch(
        `${
          process.env.REACT_APP_API_BASE_URI
        }/api/events?date=${date.toUTCString()}`,
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setEvents(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        }),
    [date]
  );

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: { main: "#ff8106" },
    },
  });

  return (
    <>
      <ContentContainer
        classes={{ root: classes.root }}
        controlTopLeft={<LinkBackToProfile />}
      >
        {" "}
        <Grid
          container
          spacing={1}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item>
              <ThemeProvider theme={defaultMaterialTheme}>
                <DatePicker
                  autoOk
                  orientation="landscape"
                  variant="static"
                  openTo="date"
                  value={date}
                  onChange={setDate}
                />
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Grid
                style={{ maxWidth: "460px", minWidth: "460px" }}
                spacing={0}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                {events.map((e, i) => (
                  <Grid item key={i} style={{ width: "100%" }}>
                    <EventItem
                      avatarSrc={e.titleImagePath}
                      fullname={e.owner.fullName}
                      action={e.description}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </ContentContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20px",
  },
}));

export default EventsPage;
