import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

function SelectCompanyForm({ companies, history }) {
  const classes = useStyles();
  const [entityAccountId, setEntityAccountId] = useState("");
  const [errUser, setErrUser] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_API_BASE_URI}/api/entityAccounts/addCurrentUser?entityAccountId=${entityAccountId}`,
      {
        method: "POST",
        credentials: "include",
      }
    )
      .then((response) => {
        if (!response.ok) setErrUser("Unknown error happened");
        else history.push("/survey");
      })
      .catch((error) => {
        setErrUser("Unknown error happened");
        console.error("Error:", error);
      });
  };

  const onChange = async (e) => {
    setEntityAccountId(e.target.value);
  };

  const options = companies.map((company) => (
    <option key={company.id} value={company.id}>
      {company.name}
    </option>
  ));

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.dbError}>{errUser}</div>
        <div className={classes.login}>Select company</div>
        <form className={classes.form} onSubmit={onSubmit}>
          <FormControl className={classes.formControl}>
            <Select
              onChange={onChange}
              value={entityAccountId}
              name="entityAccountId"
            >
              {options}
            </Select>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            Select
          </Button>
          <Link to="/createCompany" className={classes.link}>
            Create company
          </Link>
        </form>
      </div>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  dbError: {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.9em",
    lineHeight: "1em",
    letterSpacing: "0.01em",
    color: "red",
  },
  login: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.6em",
    lineHeight: "1.5em",
    letterSpacing: "0.01em",
    color: "#5C5C5C",
  },
  paper: {
    marginTop: "10vw",
    padding: "3vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "1vw",
  },
  form: {
    width: "100%",
    height: "100%",
    marginTop: "1vw",
  },
  textField: {
    display: "flex",
    alignItems: "center",
  },
  submit: {
    width: "100%",
    marginTop: "1.5vw",
    height: "2.5em",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
  },
  link: {
    display: "flex",
    justifyContent: "center",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.09em",
    color: "#5C5C5C",
    marginTop: "1.8em",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
}));

export default withRouter(SelectCompanyForm);
