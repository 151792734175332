import { ContentContainer } from "./ContentContainer";
import { BigDotBadge } from "./Badges";
import clock from "./../assets/left_column/wall-clock.svg";
import gift from "./../assets/left_column/gift_icon.svg";
import { makeStyles } from "@material-ui/core";
import { UserProfile } from "./UserProfile";
import { IconWithText } from "./IconWithText";
import LinkToProfileEdit from "./LinkToProfileEdit";

export const UserProfilePreview = ({ user }) => {
  const classes = useStyles();

  return (
    <>
      <ContentContainer
        classes={{ root: classes.container }}
        control={<LinkToProfileEdit />}
      >
        <div className={classes.root}>
          <IconWithText
            icon={clock}
            text={`${user.timeAmount} ${user.timeType}`}
          />
          <UserProfile
            pictureSrc={user.avatarImg}
            username={user.fullName}
            position={user.position}
            company={user.company}
            dateOfBirth={user.dateOfBirth}
          />
          <BigDotBadge color="secondary" variant="dot">
            <IconWithText icon={gift} text={`${user.giftAmount} gifts`} />
          </BigDotBadge>
        </div>
      </ContentContainer>
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  container: {
    marginBottom: "20px",
  },
}));
