import { makeStyles } from "@material-ui/styles";
import { ContentContainer } from "./ContentContainer";
import { PostTimemark } from "./PostTimemark";
import PostProlog from "./PostProlog";

export const PostContainer = ({
  timemark,
  avatarSrc,
  fullname,
  position,
  action,
  children,
}) => {
  const classes = useStyles();

  return (
    <ContentContainer
      classes={{ root: classes.root }}
      control={<PostTimemark value={timemark} />}
    >
      <div style={{ minWidth: "56px" }}>
        <img className={classes.avatar} src={avatarSrc} alt="" />
      </div>
      <div className={classes.content}>
        <PostProlog
          fullname={fullname}
          position={position}
          actionDescription={action}
        />
        {children}
      </div>
    </ContentContainer>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "20px",
    flexDirection: "row",
    padding: "8px",
    fontWeight: "400",
  },
  avatar: {
    borderRadius: "50%",
    width: '48px',
    height: '48px'
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  fullname: {
    fontSize: "14px",
    color: "#FF8106",
    marginRight: "15px",
  },
  action: {
    fontSize: "13px",
    color: "#979797",
  },
  position: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#979797",
    marginBottom: "8px",
  },
}));
