import { Link } from "react-router-dom";
import backIcon from "./../assets/arrow-left.svg";

const LinkBackToProfile = () => (
  <Link to="/profile">
    <img src={backIcon} alt="" />
  </Link>
);

export default LinkBackToProfile;
