import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Checkbox } from "./../components/Checkbox";
import { Collapse, Grid } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { ContentContainer } from "./../components/ContentContainer";
import { Link } from "react-router-dom";

const Avatars = ({ avatars, onClick }) => {
  const group = avatars
    ? avatars.map((avatar, index) => (
      <Avatar key={index} src={avatar.src} alt={avatar.name} />
    ))
    : null;
  if (!group) return <></>;



  return (
    <div style={{ cursor: "pointer" }}>
      <AvatarGroup max={2} onClick={onClick}>
        {group}
      </AvatarGroup>
    </div>
  );
};

const InterestCategoryItem = ({ selectedInterests, interest, onChange, userProfiles, showProfiles }) => {
  const classes = useStyles();
  const checked = selectedInterests.find((i) => i.id === interest.id) !== undefined;

  const [avatarsExpanded, setAvatarsExpanded] = useState(false);
  const avatarsForFilter = interest.avatars.map(function(obj) {
    return obj.externalUserId
  });

  var filtered = userProfiles.filter(function (value, index, arr) {
    return avatarsForFilter.includes(value.externalId);
  });


  return (
    <>
      <div className={classes.interest}>
        <div
          className={clsx(
            classes.interestHeader,
            checked && classes.iterestHeaderChecked
          )}
        >
          {interest.name}
          <Checkbox
            className={classes.interestChecker}
            checked={checked}
            onClick={() => onChange(interest.id, !checked, interest.name)}
          />
        </div>
        <div>
          {showProfiles && (selectedInterests.length >=4) && (<Avatars
            avatars={interest.avatars}
            onClick={() => setAvatarsExpanded(!avatarsExpanded)}
          />)}
        </div>
      </div>
      <Collapse in={avatarsExpanded}>
        <ContentContainer classes={{ root: classes.avatarsDetails }}>
          <Grid container spacing={1}>
            {filtered.map((profile, index) => (
              <Grid key={index} item xs={4}>
                <div className={classes.avatarDetails}>
                  <Link
                    id="efewfefef"
                    to={{
                      pathname: "/profile/colleague",
                      state: profile,
                    }}
                  >
                    <Avatar
                      className={classes.large}
                      src={profile.avatarImg}
                      alt={profile.fullName}
                    />
                    <div>{profile.fullName}</div>
                  </Link>
                </div>
              </Grid>
            ))}            
          </Grid>
        </ContentContainer>
      </Collapse>
    </>
  );
};

export default InterestCategoryItem;

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20px",
  },
  subtitle: {
    fontSize: "16",
    fontWeight: "400",
    color: "#979797",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "30px",
  },
  interest: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "10px",
    color: "#979797",
    backgroundColor: "white",
    height: "100px",
    padding: "10px",
    position: "relative",
    userSelect: "none",
    marginTop: "8px",
  },
  interestHeader: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "16px",
  },
  iterestHeaderChecked: {
    color: "#292929",
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  interestChecker: {
    cursor: "pointer",
    width: "16px",
    height: "16px",
  },
  avatarsDetails: {
    marginTop: "8px",
  },
  avatarDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#5C5C5C",
    fontSize: "10px",
  },
}));
