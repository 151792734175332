import React from 'react';
import Button from "@material-ui/core/Button";
import { ContentContainer } from "./../components/ContentContainer";
import { makeStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Modal from '@material-ui/core/Modal';

const RequestFeaturePage = ({
  user,
  feature,
  authProvider
}) => {

  const featureName = decodeURI(feature.replace("?", ""));
  const classes = useStyles();
  const { auth, firestore, db } = authProvider.fireBaseProvider;
  const newFeatureRef = firestore.collection('newFeatureRequests');
  const history = useHistory();
  
  const upvote = async () => {
    await newFeatureRef.add({
      feature: featureName,
      userName: user.fullName,
      jobTitle: user.position,
      dateCreated: db.firestore.FieldValue.serverTimestamp(),
      userEmail: auth.currentUser.email,
    });
    handleOpen();    
  }

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    history.push("/profile");
  };

  var features = {};
  features["Photo Gallery"] = "Share photos from your team-building events and other activities.";
  features["Kudos"] = "Give your gratitude to colleagues by giving them kudos and change your kudos to company's bonuses at the end of the month.";
  features["Online parties"] = "Start a discussion club based on mutual interest with your colleagues, celebrate your Birthday, or get to know your colleagues in a group meeting. Masks and backgrounds will set your festive mood.";
  features["Coffee breaks"] = "Voice your concerns in unofficial one to one with colleagues.";
  features["Challenges"] = "Be a better version of yourself! Pick a challenge and keep yourself accountable by sharing it with your colleagues.";
  features["Sport online activities"] = "Jump into professionally 5-10 minute guided training videos with your colleagues. At least one training a day can release you from back pain.";
  features["Greeting video"] = "Record a happy birthday video for your colleague, put together video reels to welcome new employees, or record a motivational speech.";

  const modalBody = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Thank you!</h2>
      <p id="simple-modal-description">
        Your vote has been recorded.
      </p>
      <Button variant="contained" className={classes.submit} onClick={handleClose}>
        Ok
          </Button>
    </div>
  );

  return (
    <>
      <ContentContainer title={"Request feature: " + featureName}>
        <div className={classes.centered}>
          <div>
            <p>{features[featureName]}</p>
            <p>This feature is under development.</p>
            You can up vote it by pressing the button "Upvote"
            </div>
          <Button variant="contained" className={classes.submit} onClick={upvote}>
            Upvote
          </Button>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalBody}
        </Modal>
      </ContentContainer>
    </>
  )
};


const useStyles = makeStyles(() => ({
  submit: {
    width: "25%",
    marginTop: "1.5vw",
    height: "2.5em",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
  },
  centered: {
    textAlign: "center",
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: "white",
    border: '2px solid silver',
    padding: "10px",
    borderRadius: "2em",
    textAlign:"center",
  },
}));

export default withRouter(RequestFeaturePage);
