import { makeStyles } from "@material-ui/styles";
import { SvgIcon } from "./SvgIcon";
import { ExpanderButton } from "./ExpanderButton";

export const UserInterestCard = ({
  userInterest,
  onClick,
  expanded,
  expandable,
  height = "85px",
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{ borderColor: userInterest.color, height: height }}
      onClick={onClick}      
    >
      <SvgIcon
        icon={userInterest.icon}
        size={"16px"}
        color={userInterest.color}
      />
      <div title={userInterest.name}>{userInterest.name}</div>
      {expandable && <ExpanderButton expanded={expanded} />}
    </div>
  );
};

export const useStyles = makeStyles(() => ({
  root: {
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: "white",
    color: "#979797",
    borderRadius: "10px",
    borderWidth: "2px",
    borderStyle: "solid",
    userSelect: "none",
    padding: "5px",
    cursor: "pointer",
  },
}));
