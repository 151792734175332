import { makeStyles } from "@material-ui/styles";

const PostProlog = ({ fullname, position, actionDescription }) => {
  const classes = useStyles();

  return (
    <>
      <div style={{ marginBottom: "8px" }}>
        <span className={classes.fullname}>{fullname}</span>
        <span className={classes.action}>{actionDescription}</span>
      </div>
      <div className={classes.position}>{position}</div>
    </>
  );
};

export default PostProlog;

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "20px",
    flexDirection: "row",
    padding: "8px",
    fontWeight: "400",
  },
  avatar: {
    borderRadius: "50%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  fullname: {
    fontSize: "14px",
    color: "#FF8106",
    marginRight: "15px",
  },
  action: {
    fontSize: "13px",
    color: "#979797",
  },
  position: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#979797",
    marginBottom: "8px",
  },
}));
