import React, { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core";
import { CssTextField } from "./CssTextField";
import { Link } from "react-router-dom";

function RegisterForm({ history, authProvider }) {
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errFirstName, setErrFirstName] = useState("");
  const [errLastName, setErrLastName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [errUser, setErrUser] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [errCompanyName, setErrCompanyName] = useState("");

  const handleFirstNameChange = useCallback(
    (event) => {
      const firstName = event.target.value;
      if (firstName.length != 0) {
        setFirstName(firstName);
        setErrFirstName("");
        setErrUser("");
      } else {
        setFirstName(lastName);
        setErrUser("");
        setErrFirstName("First name is required");
      }
    },
    [setFirstName]
  );

  const handleLastNameChange = useCallback(
    (event) => {
      const lastName = event.target.value;
      if (lastName.length != 0) {
        setLastName(lastName);
        setErrLastName("");
        setErrUser("");
      } else {
        setLastName(lastName);
        setErrUser("");
        setErrLastName("Last name is required");
      }
    },
    [setLastName]
  );

  const handleEmailChange = useCallback(
    (event) => {
      const email = event.target.value;

      if (
        email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        setEmail(email);
        setErrEmail("");
        setErrUser("");
      } else {
        setEmail(email);
        setErrEmail("Please enter valid email");
        setErrUser("");
      }
    },
    [setEmail]
  );

  const handlePasswordChange = useCallback(
    (event) => {
      const password = event.target.value;

      if (password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
        setPassword(password);
        setErrPassword("");
        setErrUser("");
      } else {
        setPassword(password);
        setErrPassword(
          "Should be at least 8 characters, 1 number, 1 lowercase, 1 uppercase"
        );
      }
    },
    [setPassword]
  );

  const handleCompanyNameChange = useCallback(
    (event) => {
      const companyName = event.target.value;
      if (companyName.length != 0) {
        setCompanyName(companyName);
        setErrCompanyName("");
        setErrUser("");
      } else {
        setCompanyName(companyName);
        setErrCompanyName("Company/Team name is required");
        setErrUser("");
      }
    },
    [setCompanyName]
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrEmail("");
    setErrPassword("");
    setErrUser("");

    fetch(`${process.env.REACT_APP_API_BASE_URI}/api/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        firstName,
        lastName,
        password,
        companyName,
      }),
      credentials: "include",
    })
      .then((response) => {
        if (response.status == 200) {
          authProvider.fireBaseProvider.auth.signInWithEmailAndPassword(
            email,
            password
          );
          history.push("/profile");
        } else {
          setErrUser(
            "Something went wrong. Please review your data and try again."
          );
        }
      })
      .catch((error) => {
        setErrUser("Unknown error happened");
        console.error("Error:", error);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.dbError}>{errUser}</div>
        <div className={classes.login}>Register</div>
        <form className={classes.form} onSubmit={onSubmit}>
          <CssTextField
            className={classes.textField}
            onChange={handleCompanyNameChange}
            value={companyName}
            error={!!errCompanyName}
            helperText={errCompanyName ? errCompanyName : ""}
            variant="outlined"
            margin="normal"
            id="companyName"
            label="Company/Team Name"
            name="companyName"
            autoComplete="companyName"
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
          />
          <CssTextField
            className={classes.textField}
            onChange={handleFirstNameChange}
            value={firstName}
            error={!!errFirstName}
            helperText={errFirstName ? errFirstName : ""}
            variant="outlined"
            margin="normal"
            id="firstName"
            label="First Name"
            name="firstName"
            autoComplete="firstName"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <CssTextField
            className={classes.textField}
            onChange={handleLastNameChange}
            value={lastName}
            error={!!errLastName}
            helperText={errLastName ? errLastName : ""}
            variant="outlined"
            margin="normal"
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="lastName"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <CssTextField
            className={classes.textField}
            onChange={handleEmailChange}
            value={email}
            error={!!errEmail}
            helperText={errEmail ? errEmail : ""}
            variant="outlined"
            margin="normal"
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <CssTextField
            className={classes.textField}
            onChange={handlePasswordChange}
            value={password}
            error={!!errPassword}
            helperText={errPassword ? errPassword : ""}
            variant="outlined"
            margin="normal"
            name="password"
            label="Password"
            type="password"
            id="password"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
            disabled={
              errPassword ||
              errEmail ||
              errLastName ||
              errFirstName ||
              errCompanyName
            }
          >
            Register
          </Button>
          <Link to="/" variant="body2" className={classes.link}>
            Login
          </Link>
        </form>
      </div>
    </Container>
  );
}

const useStyles = makeStyles(() => ({
  dbError: {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.9em",
    lineHeight: "1em",
    letterSpacing: "0.01em",
    color: "red",
  },
  login: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.6em",
    lineHeight: "1.5em",
    letterSpacing: "0.01em",
    color: "#5C5C5C",
  },
  paper: {
    marginTop: "10vw",
    padding: "3vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "1vw",
  },
  form: {
    width: "100%",
    height: "100%",
    marginTop: "1vw",
  },
  textField: {
    display: "flex",
    alignItems: "center",
  },
  submit: {
    width: "100%",
    marginTop: "1.5vw",
    height: "2.5em",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
  },
  link: {
    display: "flex",
    justifyContent: "center",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.09em",
    color: "#5C5C5C",
    marginTop: "1.8em",
  },
}));

export default withRouter(RegisterForm);
