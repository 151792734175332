import React, { useCallback, useEffect, useState } from "react";
import { HeaderContainerWeb } from "../components/Headers";
import { withRouter } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core";
import { CssTextField } from "../components/CssTextField";
import Button from "@material-ui/core/Button";

const InvitePage = ({ history, authProvider }) => {
  const [serverError, setServerError] = useState("");
  const [inviteData, setInviteData] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [errFirstName, setErrFirstName] = useState("");
  const [errLastName, setErrLastName] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const classes = useStyles();

  const invitationToken = new URLSearchParams(window.location.search).get(
    "invitationToken"
  );

  useEffect(
    () =>
      fetch(
        `${process.env.REACT_APP_API_BASE_URI}/api/inviteData?invitationToken=${invitationToken}`,
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setInviteData(data);
        })
        .catch((error) => {
          setServerError("Unknown error happened");
          console.error("Error:", error);
        }),
    []
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    setServerError("");

    fetch(`${process.env.REACT_APP_API_BASE_URI}/api/utilizeInvitationToken`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ invitationToken, firstName, lastName, password }),
      credentials: "include",
    })
      .then((response) => {
        if (response.status == 200) {
          authProvider.fireBaseProvider.auth.signInWithEmailAndPassword(inviteData.email, password);
          history.push("/profile")
        }
        else {
          setServerError("Something went wrong. Please review your data and try again.");
        }
      })
      .catch((error) => {
        setServerError("Unknown error happened. Please contact support");
        console.error("Error:", error);
      });
  };

  const handleFirstNameChange = useCallback(
    (event) => {
      const firstName = event.target.value;
      if (firstName.length != 0) {
        setFirstName(firstName);
        setErrFirstName("");
      }
      else {
        setFirstName(firstName);
        setErrFirstName("First name is required");
      }
    },
    [setFirstName]
  );

  const handleLastNameChange = useCallback(
    (event) => {
      const lastName = event.target.value;
      if (lastName.length != 0) {
        setLastName(lastName);
        setErrLastName("");
      }
      else {
        setLastName(lastName);
        setErrLastName("Last name is required");
      }
    },
    [setLastName]
  );

  const handlePasswordChange = useCallback(
    (event) => {
      const password = event.target.value;
      if (password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
        setPassword(password);
        setErrPassword("");
      }
      else {
        setPassword(password);
        setErrPassword("Should be at least 8 characters, 1 number, 1 lowercase, 1 uppercase");
      }

    },
    [setPassword]
  );

  return (
    <>
      <HeaderContainerWeb logo authProvider={authProvider}/>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.serverError}>{serverError}</div>
          <div className={classes.formHeader}>Invite</div>
          <form className={classes.form} onSubmit={onSubmit}>
            <CssTextField
              className={classes.textField}
              value={inviteData.companyName}
              error={!!inviteData.companyName}
              helperText={""}
              variant="outlined"
              margin="normal"
              id="companyName"
              label="Company"
              name="companyName"
              autoComplete="company"
              autoFocus
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
            <CssTextField
              className={classes.textField}
              value={inviteData.email}
              error={!!inviteData.email}
              helperText={""}
              variant="outlined"
              margin="normal"
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              autoFocus
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
            <CssTextField
              className={classes.textField}
              onChange={handleFirstNameChange}
              value={firstName}
              error={!!errFirstName}
              helperText={errFirstName ? errFirstName : ""}
              variant="outlined"
              margin="normal"
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="firstName"
              autoFocus
              InputLabelProps={{
                shrink: true,
              }}
            />
            <CssTextField
              className={classes.textField}
              onChange={handleLastNameChange}
              value={lastName}
              error={!!errLastName}
              helperText={errLastName ? errLastName : ""}
              variant="outlined"
              margin="normal"
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lastName"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <CssTextField
              className={classes.textField}
              onChange={handlePasswordChange}
              value={password}
              error={!!errPassword}
              helperText={errPassword ? errPassword : ""}
              variant="outlined"
              margin="normal"
              name="password"
              label="Password"
              type="password"
              id="password"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
              disabled={errFirstName || errLastName || errPassword}
            >
              Register
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
};

const useStyles = makeStyles(() => ({
  serverError: {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.9em",
    lineHeight: "1em",
    letterSpacing: "0.01em",
    color: "red",
  },
  formHeader: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.6em",
    lineHeight: "1.5em",
    letterSpacing: "0.01em",
    color: "#5C5C5C",
  },
  paper: {
    marginTop: "10vw",
    padding: "3vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "1vw",
  },
  form: {
    width: "100%",
    height: "100%",
    marginTop: "1vw",
  },
  textField: {
    display: "flex",
    alignItems: "center",
  },
  submit: {
    width: "100%",
    marginTop: "1.5vw",
    height: "2.5em",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
  },
}));

export default withRouter(InvitePage);
