import { withRouter } from "react-router-dom";
import { useLocation } from "react-router";
import { UserProfile } from "./../components/UserProfile";
import { ContentContainer } from "./../components/ContentContainer";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CssTextField } from "../components/CssTextField";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import LinkBackToColleagues from "../components/LinkBackToColleagues";
import { Title } from "./../components/Title";
import { SvgIcon } from "./../components/SvgIcon";

const UserProfileReadOnlyPage = (props) => {
  const location = useLocation();
  const profile = location.state;
  console.log(profile);
  const classes = useStyles();
  const openChatHandlerWithConversation = props.openChatHandlerWithConversation;

  return (
    <>
      <ContentContainer
        classes={{ root: classes.root }}
        controlTopLeft={<LinkBackToColleagues />}
      >
        <UserProfile pictureSrc={profile.avatarImg} />
        <Grid container spacing={4} justify="center">
          <Grid item lg={3} sm={3} xs={12}>
            <Title
              username={profile.fullName}
              position={profile.position}
              companyPrefix="Company/Team/Department"
              company={profile.company}
              dateOfBirth={profile.dateOfBirth}
              employmentDate={profile.employmentDate}
              numberOfYears={profile.numberOfYears}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={() => openChatHandlerWithConversation({ externalId: profile.externalId, fullName: profile.fullName, imgURL: profile.avatarImg })}
            >
              Start conversation
            </Button>
          </Grid>
          <Grid
            item
            lg={3}
            sm={3}
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Typography variant="body1">List of interests</Typography>
            {profile.interests.map((interest, index) => (
              <Grid key={index} item xs={12} lg={12}>
                <div title={interest.name}>
                  <SvgIcon
                    icon={interest.icon}
                    size={"16px"}
                    color={interest.color}
                  />
                  <span style={{ paddingLeft: "10px" }}>{interest.name}</span>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </ContentContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#979797",
  },
  textField: {
    display: "flex",
    alignItems: "center",
  },
  submit: {
    width: "100%",
    marginTop: "1.5vw",
    height: "2.5em",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
  },
  upload: {
    width: "85%",
    marginLeft: "7.5%",
    height: "2.5em",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    display: "block",
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "2.5em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
    cursor: "pointer",
  },
}));

export default withRouter(UserProfileReadOnlyPage);
