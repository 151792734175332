import { useEffect, useState } from "react";
import { ContentContainer } from "./../components/ContentContainer";
import LinkBackToProfile from "../components/LinkBackToProfile";
import { UserProfile } from "./../components/UserProfile";
import { Grid } from "@material-ui/core";
import { UserProfileCard } from "../components/UserProfileCard";
import { makeStyles } from "@material-ui/core/styles";

const ColleaguesPage = ({ user, openChatHandlerWithConversation}) => {
  const classes = useStyles();
  const [userProfiles, setUserProfiles] = useState([]);
  const [expandedProfileIndex, setExpandedProfileIndex] = useState();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URI}/api/current/companyProfiles`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          /*var filtered = result.filter(function (value, index, arr) {
            return value.fullName != user.fullName;
          });*/
          setUserProfiles(result);
          //setCurrentUserName(userName);
        },
        (error) => {}
      );
  }, [user]);

  const expandProfile = (profileIndex) => {
    if (profileIndex === expandedProfileIndex) {
      setExpandedProfileIndex(-1);
    } else {
      setExpandedProfileIndex(profileIndex);
    }
  };

  return (
    <ContentContainer
      classes={{ root: classes.root }}
      controlTopLeft={<LinkBackToProfile />}
    >
      <div className={classes.subtitle}>Colleagues</div>
      <Grid container spacing={1}>
        {userProfiles.map((profile, index) => (
          <Grid key={index} item xs={12} lg={3}>
            <UserProfileCard
              key={profile.externalId}
              profile={profile}
              onClick={() => {
                expandProfile(index);
              }}
              expanded={expandedProfileIndex === index}
              openChatHandlerWithConversation={openChatHandlerWithConversation}
              user={user}
            />
          </Grid>
        ))}
      </Grid>
    </ContentContainer>
  );
};

export default ColleaguesPage;

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20px",
  },
  subtitle: {
    fontSize: "16",
    fontWeight: "400",
    color: "#979797",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "30px",
  },
  interest: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "10px",
    color: "#979797",
    backgroundColor: "white",
    height: "130px",
    padding: "10px",
    position: "relative",
    userSelect: "none",
    marginTop: "8px",
  },
  interestHeader: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "16px",
  },
  iterestHeaderChecked: {
    color: "#292929",
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  interestChecker: {
    cursor: "pointer",
    width: "16px",
    height: "16px",
  },
  avatarsDetails: {
    marginTop: "8px",
  },
  avatarDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#5C5C5C",
    fontSize: "10px",
  },
}));
