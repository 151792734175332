import { makeStyles } from "@material-ui/styles";


export const PostTimemark = ({ value }) => {
    const classes = useStyles();

    return <div className={classes.timemark}>{value}</div>;
};

const useStyles = makeStyles(() => ({
    timemark: {
        fontWeight: '600',
        fontSize: '10px',
        color: '#979797',
        textAlign: 'right'
    }
}));
