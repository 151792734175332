import { UserProfilePreview } from "./UserProfilePreview";
import { SidebarUserInterests } from "./SidebarUserInterests";
import { PhotoGallery } from "./PhotoGallery";

export const Sidebar = ({ user, categories, expandCategoryHandler }) => {
  return (
    <>
      <UserProfilePreview user={user} />
      <SidebarUserInterests interests={user.interests} categories={categories} expandCategoryHandler={expandCategoryHandler}/>
      <PhotoGallery />
    </>
  );
};
