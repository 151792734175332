import { makeStyles } from "@material-ui/core";

const Photo = ({ src }) => <img src={src} alt='' />;

const LastPhoto = ({ left, src }) => {
    const classes = useStyles();
    return (
        <div>
            <Photo src={src} />
            <div className={classes.lastPhoto}>
                <span>+{left}</span>
            </div>
        </div>
    )
};

export const ExtraPhotos = ({ srcs, count }) => {
    const classes = useStyles();

    const photos = srcs.slice(0, 4).map((src, index) => index === 3
        ? <LastPhoto key={index} src={src} left={count - 4} />
        : <Photo key={index} src={src} />);

    return <div className={classes.albPreview}>{photos}</div>;
};

const useStyles = makeStyles(() => ({
    lastPhoto: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
        backgroundColor: 'white',
        fontSize: '12px',
        fontWeight: '300',
        color: '#FF8106',
        width: '24px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center'
    },
    albPreview: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
        alignSelf: 'center',
        gridGap: '8px',
        '& > div:last-child': {
            filter: 'opacity(50%)',
        }
    }
}));
