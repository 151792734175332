import plus from "./../assets/left_column/plus_button.svg";
import teambuildingMain from "./../assets/left_column/teambuilding_main_photo.svg";
import teamPrev1 from "./../assets/left_column/team_prev_1.svg";
import teamPrev2 from "./../assets/left_column/team_prev_2.svg";
import teamPrev3 from "./../assets/left_column/team_prev_3.svg";
import teamPrev4 from "./../assets/left_column/team_prev_4.svg";
import fineMain from "./../assets/left_column/fine_main_photo.svg";
import finePrev1 from "./../assets/left_column/fine_prev_img_1.svg";
import finePrev2 from "./../assets/left_column/fine_prev_img_2.svg";
import finePrev3 from "./../assets/left_column/fine_prev_img_3.svg";
import finePrev4 from "./../assets/left_column/fine_prev_img_4.svg";
import sportsMain from "./../assets/left_column/sport_main_photo.svg";
import sportsPrev1 from "./../assets/left_column/sport_prev_img_1.svg";
import sportsPrev2 from "./../assets/left_column/sport_prev_img_2.svg";
import sportsPrev3 from "./../assets/left_column/sport_prev_img_3.svg";
import sportsPrev4 from "./../assets/left_column/sport_prev_img_4.svg";
import { AlbumTitle } from "./AlbumTitle";
import { MainPhoto } from "./MainPhoto";
import { ExtraPhotos } from "./ExtraPhotos";
import { makeStyles } from "@material-ui/styles";
import { ContentContainer } from "./ContentContainer";

export const ShowAlbumPhotos = ({ srcs }) => {
  const classes = useStyles();
  const images = [...srcs];
  const [first, ...rest] = images;
  return (
    <div className={classes.showAlbum}>
      <MainPhoto src={first} />
      <ExtraPhotos srcs={rest} count={images.length - 1} />
    </div>
  );
};

const albums = [
  {
    name: "Teambuilding",
    photoAmount: "9",
    altAlbum: "teambuilding",
    albumLink: "#",
    photos: [
      teambuildingMain,
      teamPrev1,
      teamPrev2,
      teamPrev3,
      teamPrev4,
      teamPrev1,
      teamPrev1,
      teamPrev1,
      teamPrev1,
    ],
  },
  {
    name: "Fine dinner",
    photoAmount: "5",
    altAlbum: "fineDinner",
    albumLink: "#",
    photos: [
      fineMain,
      finePrev1,
      finePrev2,
      finePrev3,
      finePrev4,
      finePrev1,
      finePrev1,
    ],
  },
  {
    name: "Sports in our life",
    photoAmount: "22",
    altAlbum: "sports",
    albumLink: "#",
    photos: [
      sportsMain,
      sportsPrev1,
      sportsPrev2,
      sportsPrev3,
      sportsPrev4,
      sportsPrev3,
      sportsPrev3,
      sportsPrev3,
    ],
  },
];

export const PhotoGallery = () => {
  const classes = useStyles();

  return (
    <>
      <ContentContainer title={"Photo Gallery"}>
        <div className={classes.photoGallery}>
          <div className={classes.photoGalleryAlbums}>
            {albums.map((alb) => {
              return (
                <div key={alb.name} className={classes.alb}>
                  <AlbumTitle key={alb.name} alb={alb} />
                  <ShowAlbumPhotos srcs={alb.photos} />
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.addButton}>
          <a href="#">
            <img src={plus} alt="plus" />
          </a>
        </div>
        <div className={classes.seeAll}>
          <div>See all</div>
        </div>
      </ContentContainer>
    </>
  );
};

const useStyles = makeStyles(() => ({
  showAlbum: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "8px",
  },
  photoGallery: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: "100%",
  },
  photoGalleryAlbums: {
    display: "grid",
    gridTemplateRows: "repeat(3, 1fr)",
    alignItems: "center",
    gridGap: "8px",
  },
  addButton: {
    margin: "0 auto",
    marginTop: "55px",
    marginBottom: "45px",
  },
  seeAll: {
    marginBottom: "25px",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "21px",
    textAlign: "center",
    letterSpacing: "1.25px",
    color: "#FF8106",
  },
  alb: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridGap: "8px",
  },
}));
