import { ContentContainer } from "./../components/ContentContainer";
import { Grid } from "@material-ui/core";
import InterestCategoryGridItem from "./InterestCategoryGridItem";

const InterestsPageAllInterests = ({
  selectedInterests,
  categories,
  onChangeInterest,
  expandedCategoryId,
  onCategoryExpanded,
}) => (
  <ContentContainer title={"All interests"}>
    <Grid container spacing={1}>
      {categories.map((category, index) => (
        <Grid key={index} item lg={3} sm={3} xs={12}>
          <InterestCategoryGridItem
            key={index}
            selectedInterests={selectedInterests}
            category={category}
            onChangeInterest={onChangeInterest}
            expanded={category.id === expandedCategoryId}
            onExpanded={onCategoryExpanded}
          />
        </Grid>
      ))}
    </Grid>
  </ContentContainer>
);

export default InterestsPageAllInterests;
