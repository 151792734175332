import { Link } from "react-router-dom";
import editIcon from "./../assets/left_column/edit_icon.svg";

const LinkToProfileInterests = () => (
  <Link to="/profile/interests">
    <img src={editIcon} alt="" />
  </Link>
);

export default LinkToProfileInterests;
