import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: "1278px",
    marginTop: "2.5em",
    margin: "15vw 15vw",
    padding: "0.1vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "1vw",
  },
  title: {
    marginTop: "1vh",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.2em",
    lineHeight: "1.5em",
    letterSpacing: "0.01em",
    color: "#979797",
  },
  question: {
    marginTop: "10vh",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "3vw",
    letterSpacing: "0.01em",
    color: "#5C5C5C",
  },
  explanation: {
    marginTop: "2vh",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1em",
    lineHeight: "1.3em",
    letterSpacing: "0.01em",
    color: "#979797",
  },
  moods: {
    display: "flex",
    padding: "1px",
    marginTop: "7vh",
    "& > *": {
      margin: "0 20px",
    },
  },
  moodAnimation: {
    display: "block",
    width: "6vw",
    height: "6vw",
    margin: "0 auto",
  },
  checkbox: {
    marginTop: "2vh",
    display: "flex",
    justifyContent: "center",
  },
  submitButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "14vh",
    marginBottom: "5vh",
    height: "2.5em",
    width: "100%",
  },
  submitButton: {
    width: "50%",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "1.3em",
    lineHeight: "1.4em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
  },
}));
