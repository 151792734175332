import React, { useEffect, useState } from "react";
import { HeaderContainerWeb } from "../components/Headers";
import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import { CssTextField } from "../components/CssTextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withRouter } from "react-router-dom";

const CreateCompanyPage = ({ history }) => {
  const [parents, setParents] = useState([]);
  const [levels, setLevels] = useState([]);
  const [serverError, setServerError] = useState([]);
  const [name, setName] = useState("");
  const [hierarchyLevel, setHierarchyLevel] = useState(null);
  const [parentId, setParentId] = useState(null);

  useEffect(
    () =>
      fetch(`${process.env.REACT_APP_API_BASE_URI}/api/createEntityAccount`, {
        method: "GET",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          setParents(data.parents);
          setLevels(data.hierarchyLevels);
        })
        .catch((error) => {
          setServerError("Unknown error happened");
          console.error("Error:", error);
        }),
    []
  );

  const classes = useStyles();

  const onSubmit = async (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_BASE_URI}/api/entityAccounts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, hierarchyLevel, parentId }),
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) setServerError("Unknown error happened");
        else history.push("/profile");
      })
      .catch((error) => {
        setServerError("Unknown error happened");
        console.error("Error:", error);
      });
  };

  const levelOptions = levels.map((level) => (
    <option key={level.id} value={level.id}>
      {level.name}
    </option>
  ));

  const onParentChange = async (e) => {
    setParentId(e.target.value);
  };

  const onLevelChange = async (e) => {
    setHierarchyLevel(e.target.value);
  };

  const onNameChange = async (e) => {
    setName(e.target.value);
  };

  const parentField = parents.length ? (
    <FormControl className={classes.formControl}>
      <InputLabel id="paren-select-label">Parent company</InputLabel>
      <Select
        label="paren-select-label"
        onChange={onParentChange}
        value={parentId}
        name="parentId"
      >
        {parents.map((company) => (
          <option key={company.id} value={company.id}>
            {company.name}
          </option>
        ))}
      </Select>
    </FormControl>
  ) : (
    <input type="hidden" name="parentId" value="null"></input>
  );
  return (
    <>
      <HeaderContainerWeb logo />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.serverError}>{serverError}</div>
          <div className={classes.header}>Create company</div>
          <form className={classes.form} onSubmit={onSubmit}>
            <CssTextField
              className={classes.textField}
              onChange={onNameChange}
              value={name}
              error={!!name}
              helperText={""}
              variant="outlined"
              margin="normal"
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              InputLabelProps={{
                shrink: true,
              }}
            />
            {parentField}
            <FormControl className={classes.formControl}>
              <InputLabel id="level-select-label">Hierarchy level</InputLabel>
              <Select
                label="level-select-label"
                onChange={onLevelChange}
                value={hierarchyLevel}
                name="hierarchyLevel"
              >
                {levelOptions}
              </Select>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              Create
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  serverError: {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.9em",
    lineHeight: "1em",
    letterSpacing: "0.01em",
    color: "red",
  },
  header: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.6em",
    lineHeight: "1.5em",
    letterSpacing: "0.01em",
    color: "#5C5C5C",
  },
  paper: {
    marginTop: "10vw",
    padding: "3vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "1vw",
  },
  form: {
    width: "100%",
    height: "100%",
    marginTop: "1vw",
  },
  textField: {
    display: "flex",
    alignItems: "center",
  },
  submit: {
    width: "100%",
    marginTop: "1.5vw",
    height: "2.5em",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
}));

export default withRouter(CreateCompanyPage);
