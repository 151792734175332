import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";

export const useStyles = makeStyles(() => ({
  dbError: {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.9em",
    lineHeight: "1em",
    letterSpacing: "0.01em",
    color: "red",
  },
  login: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "1.6em",
    lineHeight: "1.5em",
    letterSpacing: "0.01em",
    color: "#5C5C5C",
  },
  paper: {
    marginTop: "10vw",
    padding: "3vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "1vw",
  },
  form: {
    width: "100%",
    height: "100%",
    marginTop: "1vw",
  },
  textField: {
    display: "flex",
    alignItems: "center",
  },
  submit: {
    width: "100%",
    marginTop: "1.5vw",
    height: "2.5em",
    backgroundColor: "#FF8106",
    "&:hover": {
      backgroundColor: "#FF8106",
    },
    borderRadius: "2em",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.01em",
    textTransform: "none",
    color: "#FFFFFF",
  },
  link: {
    display: "flex",
    justifyContent: "center",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    textAlign: "center",
    letterSpacing: "0.09em",
    color: "#5C5C5C",
    marginTop: "1.8em",
    cursor: "pointer",
  },
}));

export const CssTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      fontSize: "0.8em",
      "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active":
        {
          "-webkit-box-shadow": "0 0 0 30px white inset !important",
        },
    },
    "& label.MuiFormLabel-root": {
      color: "#FF8106",
      fontSize: "1em",
      marginLeft: "25px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "3em",
      height: "2.3em",
      width: "85%",
      "& fieldset": {
        borderColor: "#DEE2E6",
      },
      "&:hover fieldset": {
        borderColor: "#DEE2E6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#DEE2E6",
      },
    },
  },
})(TextField);
