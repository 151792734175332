import { makeStyles } from "@material-ui/core";

export const IconWithText = ({ icon, text }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img src={icon} alt='' />
            <div className={classes.text}>{text}</div>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'center'
    },
    text: {
        fontSize: '12px',
        fontWeight: '400',
        color: '#979797',
        whiteSpace: 'nowrap'
    }
}));
