import React from "react";
import {HeaderContainerWeb} from "../Headers";
import LoginBody from "./login_body";

const Login = (props) => {
    const { authProvider, setAuthProvider } = props;

    return (
        <>
          <HeaderContainerWeb logo authProvider={authProvider}/>
          <LoginBody authProvider={authProvider} setAuthProvider={setAuthProvider}/>
        </>
    )
}

export default Login;