import React, { useState, useEffect } from "react";
import userAvatar1 from "./../assets/center_column/userAvatar_small.svg";
import post1MainPhoto from "./../assets/center_column/post1_photo_main.svg";
import post1PhotoSmall1 from "./../assets/center_column/post1_photo_small1.svg";
import post1PhotoSmall2 from "./../assets/center_column/post1_photo_small2.svg";
import post1PhotoSmall3 from "./../assets/center_column/post1_photo_small3.svg";
import post1PhotoSmall4 from "./../assets/center_column/post1_photo_small4.svg";
import userAvatar2 from "./../assets/center_column/userAvatar2_small.svg";
import userAvatar3 from "./../assets/center_column/userAvatar3_small.svg";
import post3Img1 from "./../assets/center_column/post3_img1.svg";
import post3Img2 from "./../assets/center_column/post3_img2.svg";
import post3Img3 from "./../assets/center_column/post3_img3.svg";
import post3Img4 from "./../assets/center_column/post3_img4.svg";
import { Post } from "./Post";
import { SurveyPost } from "./SurveyPost";

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import EventList from "./EventList";

function PostTypeSwitcher({ wallRecord }) {
  switch (wallRecord.recordType) {
    case "basicPost":
      return <Post wallRecord={wallRecord} />;
    case "survey":
      return <SurveyPost wallRecord={wallRecord} />;
    default:
      return <Post wallRecord={wallRecord} />;
  }
}

export const Wall = (props) => {
  const { auth, firestore, db } = props.authProvider.fireBaseProvider;
  const user = props.user;
  /*const [userFireBase] = useAuthState(auth);*/
  const postsRef = firestore.collection("wall/" + user.company + "/posts");
  const query = postsRef.orderBy("dateCreated", "desc").limit(25);
  const [posts] = useCollectionData(query, { idField: "id" });

  const mappedPosts = (posts || []).map((wallRecord, index) => (
    <PostTypeSwitcher wallRecord={wallRecord} key={index} />
  ));

  return (
    <>
      <EventList
        events={props.events}
        authProvider={props.authProvider}
        user={user}
      />
      {mappedPosts}
    </>
  );
};
